<template>
  <div>
    <h4 style="text-align: center;font-size: 28px;margin-top: 60px">选择时间</h4>
    <div class="timeModel-wrapper">
      <div class="timeModel-left">
        <div class="timeModel-left-time">
          <div class="timeModel-left-time-p">
            <p>入住时间: {{nowTime}}</p>
            <p>离店时间: {{endTime}}</p>
          </div>
        </div>
      </div>
      <div class="timeModel-right">
        <h4>选择入住时长</h4>
        <div>
          <div>
            <Button :type="actionIndex === index ? 'error' : 'primary'" size="large" style="margin: 12px 12px 12px 0" v-for="(item,index) in dayArr" @click="sureDay(item,index)">{{item.label}}</Button>
          </div>
        </div>
      </div>
    </div>
    <transition name="moveTop">
      <div v-if="flagDay" class="time-button">
        <div style="width: 40%">
          <Button type="primary" size="large" long @click="selectTime" :loading="buttonLoading">确定</Button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import {getLocalStorage} from "../../common/js/localStorage"

  export default {
    name: "dayModel",
    props: {
      buttonLoading: Boolean
    },
    data() {
      return {
        dayArr: [],
        actionIndex: -1,
        nowTime: '2020-5-5 10:00',
        endTime: '选择入住时长',
        flagDay: false,
        dayTime: JSON.parse(getLocalStorage('zs-offline-hotelDetailsObj').hotelOutRoomTimeDto).dayTime
      }
    },
    methods: {
      /*确定离店时间*/
      selectTime() {
        this.$emit('selectTime',this.nowTime,this.endTime,this.actionIndex+1)
      },
      /*选择离店时间*/
      sureDay(val,index) {
        this.actionIndex = index
        let activeDate = new Date(val.value)
        this.endTime = activeDate.getFullYear() + '-' + (activeDate.getMonth() + 1) + '-' + activeDate.getDate() + ' ' + activeDate.getHours() + ':00'
        if(!this.flagDay) {
          this.flagDay = true
        }
      },
      /*初始化*/
      initData() {
        let nowDate = new Date()
        let nd = nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate() + ' 00:00'
        this.nowTime = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate() + ' ' + nowDate.getHours() + ':00'
        let nowDateTime = new Date(nd).getTime()
        if(nowDate.getHours() < 7) {
          for(let i=0;i<9;i++) {
            let obj = {
              value: nowDateTime + (i * 24 + this.dayTime) * 3600000,
              label: (i + 1)+'天'
            }
            this.dayArr.push(obj)
          }
        } else {
          for(let i=1;i<10;i++) {
            let obj = {
              value: nowDateTime + (i * 24 + this.dayTime) * 3600000,
              label: i+'天'
            }
            this.dayArr.push(obj)
          }
        }
      }
    },
    created() {
      this.initData()
    }
  }
</script>

<style scoped lang="stylus">
  .timeModel-wrapper
    padding 48px 0
    display flex
    justify-content space-around
    .timeModel-left
      width 50%
      > h4
        font-size 18px
        font-weight 600
      .timeModel-left-time
        width 100%
        height 100%
        display flex
        align-items center
        justify-content center
        font-size 18px
        font-weight 600
    .timeModel-right
      width 50%
      > h4
        font-size 18px
        font-weight 600
      .timeModel-right-h4
        margin-top 15px
        font-size 16px
        font-weight 600
  .timeModel-left-time-p
    margin 0 48px
    width 100%
    > p
      padding 15px 0
      width 100%
      border-bottom 1px solid #e8eaec
      text-align center
      letter-spacing 1px
  .time-button
    margin-top 60px
    padding-bottom 81px
    width 100%
    display flex
    align-items center
    justify-content center
  .moveTop-enter-active, .moveTop-leave-active
    transition all .4s
    opacity 1
  .moveTop-enter, .moveTop-leave-to
    transform translateY(100%)
    opacity 0
</style>