export function calculationDateForDay(startDateFormat, endDateFormat) {
  let startTime = startDateFormat.getTime()
  let endTime = endDateFormat.getTime()
  let dateList = []
  if (startDateFormat.getHours() < 7) {
    startTime = startTime - 86400000
    endTime = endDateFormat.getTime() - 86400000
  } else if(startDateFormat.getHours() <= endDateFormat.getHours()) {
    endTime = endDateFormat.getTime() - 86400000
  }
  for (let i = startTime; i <= endTime; i = i + 86400000) {
    let dateFormatNew = new Date(i)
    let dateFormat = dateFormatNew.getFullYear() + '-' + repairZero((dateFormatNew.getMonth() + 1)) + '-' + repairZero(dateFormatNew.getDate())
    dateList.push(dateFormat)
  }
  return dateList
}

function repairZero(val) {
  if (val < 10) {
    val = '0' + val
  }
  return val
}