<template>
  <div class="timeModel-wrapper">
    <div class="timeModel-left">
      <div class="timeModel-left-time">
        <div class="timeModel-left-time-p">
          <p>入住时间: {{nowTime}}</p>
          <p>离店时间: {{endTime}}</p>
          <p>共 {{timeNum}} 小时</p>
        </div>
      </div>
    </div>
    <div class="timeModel-right">
      <h4>选择离店时间</h4>
      <div>
        <div v-if="todayArr.length !== 0">
          <h4 class="timeModel-right-h4">今天 ({{todayFont}})</h4>
          <Button :type="actionIndex.one === 1 && actionIndex.two === index ? 'error' : 'primary'" size="large" style="margin: 12px 12px 12px 0" v-for="(item,index) in todayArr" @click="sureNow(item,index)">{{item}}</Button>
        </div>
        <div v-if="tomorrowArr.length !== 0">
          <h4 class="timeModel-right-h4">明天 ({{tomorrowFont}})</h4>
          <Button :type="actionIndex.one === 2 && actionIndex.two === index ? 'error' : 'primary'" size="large" style="margin: 12px 12px 12px 0" v-for="(item,index) in tomorrowArr" @click="sureNext(item,index)">{{item}}</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getLocalStorage} from "../../common/js/localStorage"
  import {formatNumber} from "../../common/js/formatTime";

  export default {
    name: "timeModel",
    data() {
      return {
        todayArr: [],
        tomorrowArr: [],
        todayFont: '',
        tomorrowFont: '',
        actionIndex: {
          one: null,
          two: null
        },
        nowTime: '2020-5-5 10:00',
        timeNum: 0,
        endTime: '请选择离店时间',
        outTimeObj: JSON.parse(getLocalStorage('zs-offline-hotelDetailsObj').hotelOutRoomTimeDto)
      }
    },
    methods: {
      /*确定点击 今天*/
      sureNow(val,index) {
        this.actionIndex.one = 1
        this.actionIndex.two = index
        let time = this.todayFont + ' ' + val
        this.endTime = time
        let nt = new Date().getHours()
        let et = new Date(this.endTime).getHours()
        this.timeNum = et - nt
        if(this.timeNum <= 0) {
          this.$Notice.warning({
            title: '选择时间错误',
            desc: '请重新选择离店时间'
          })
          this.initData()
        } else {
          this.$emit('sure', time)
        }
      },
      /*确定点击 明天*/
      sureNext(val,index) {
        this.actionIndex.one = 2
        this.actionIndex.two = index
        let time = this.tomorrowFont + ' ' + val
        this.endTime = time
        let nt = new Date().getHours()
        let et = new Date(this.endTime).getHours()
        this.timeNum = et - nt + 24
        if(this.timeNum <= 0) {
          this.$Notice.warning({
            title: '选择时间错误',
            desc: '请重新选择离店时间'
          })
          this.initData()
        } else {
          this.$emit('sure', time)
        }
      },
      /*初始化*/
      initData() {
        this.todayArr = []
        this.tomorrowArr = []
        let nowDate = new Date()
        let nextDate = new Date(nowDate.getTime() + 86400000)
        this.todayFont = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate()
        this.tomorrowFont = nextDate.getFullYear() + '-' + (nextDate.getMonth() + 1) + '-' + nextDate.getDate()
        let mm = nowDate.getMinutes()
        if(mm < 10) {
          mm = '0'+mm
        }
        this.nowTime = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate() + ' ' + nowDate.getHours() + ':' + mm
        let max = this.outTimeObj.hourTimeLatest
        let min = this.outTimeObj.hourTimeEarliest
        let h = new Date().getHours()
        if(h < min) {
          for(let i = min;i <= max; i++) {
            let time = formatNumber(i) + ':00'
            this.todayArr.push(time)
          }
        }
        if(h > min) {
          for(let i = min;i <= max; i++) {
            let time = formatNumber(i) + ':00'
            if((i - 1) >= h) {
              this.todayArr.push(time)
            }
          }
          for(let i = min;i <= h; i++) {
            let time = formatNumber(i) + ':00'
            this.tomorrowArr.push(time)
          }
        }
        if(h > max) {
          this.todayArr = []
        }
      }
    },
    created() {
      this.initData()
    }
  }
</script>

<style scoped lang="stylus">
  .timeModel-wrapper
    padding 48px 0
    display flex
    justify-content space-around
    .timeModel-left
      width 50%
      > h4
        font-size 18px
        font-weight 600
      .timeModel-left-time
        width 100%
        height 100%
        display flex
        align-items center
        justify-content center
        font-size 18px
        font-weight 600
    .timeModel-right
      width 50%
      > h4
        font-size 18px
        font-weight 600
      .timeModel-right-h4
        margin-top 15px
        font-size 16px
        font-weight 600
  .timeModel-left-time-p
    margin 0 48px
    width 100%
    > p
      padding 15px 0
      width 100%
      border-bottom 1px solid #e8eaec
      text-align center
      letter-spacing 1px
</style>
