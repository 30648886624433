export let width = getTableWidth()

//设置iview中 table宽度
function getTableWidth() {
  let w = document.body.clientWidth
  w = w - 240 - 16 - 32
  return w
}

//获取URL参数
export function GetUrlParams() {
  let urlAddress = window.location.href.split('?')
  let url = '?'+urlAddress[1] //获取url中"?"符后的字串
  let theRequest = new Object()
  if (url.indexOf("?") != -1) {
    let str = url.substr(1)
    let strs = str.split("&")
    for(let i = 0; i < strs.length; i ++) {
      //unescape() 函数可对通过 escape() 编码的字符串进行解码。
      theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1])
    }
  }
  return theRequest
}

//数组去重
export function unique (arr) {
  let newJson = []; //盛放去重后数据的新数组
  arr.forEach((item1) => {
    let flag = true;  //建立标记，判断数据是否重复，true为不重复
    newJson.forEach((item2) => {
      if(item1.idCardDto === item2.idCardDto){ //让json数组对象的内容与新数组的内容作比较，相同的话，改变标记为false
        flag = false;
      }
    })
    if(flag){ //判断是否重复
      newJson.push(item1); //不重复的放入新数组。  新数组的内容会继续进行上边的循环。
    }
  })
  return newJson
}

