<template>
  <div>
    <transition name="popupBottomMove">
      <div class="popup-bottom" ref="popup">
        <!--页面顶部-->
        <div class="popup-bottom-title">
          <p @click="back" class="popup-bottom-title-button">
            <Button shape="circle" type="primary" size="large" :icon="'ios-arrow-down'">关闭</Button>
          </p>
          <div class="popup-bottom-title-steps">
            <div style="width: 70%">
              <Steps :current="stepIndex">
                <Step :title="item.title" :content="item.content" v-for="(item,index) in steps" :key="item.id"></Step>
              </Steps>
            </div>
          </div>
          <div class="popup-bottom-time">{{backTime}}s</div>
        </div>
        <!--页面内容-->
        <div class="popup-bottom-body" ref="popupBottomWrapper">
          <div>
            <Carousel v-model="stepIndex" dots="none" arrow="never">
              <!--扫描二维码-->
              <CarouselItem>
                <loadingPage v-show="loadingInit"></loadingPage>
                <search-obj-w-s v-show="!loadingInit" @sendUseObj="gerUserObj"></search-obj-w-s>
              </CarouselItem>
              <!--选择订单-->
              <CarouselItem>
                <div v-if="stepIndex === 1" class="order-list-wrapper">
                  <Card v-for="(item,index) in orderArr" :key="item.id" style="margin: 15px">
                    <div class="order-list">
                      <div>
                        <h4>{{item.roomTypeDto.typeName}} {{item.roomInfoDto.name}}</h4>
                      </div>
                      <div>
                        <Button v-if="item.orderStatus === 2" type="error" @click="activeOrder(item,index)">补录入住人</Button>
                      </div>
                    </div>
                  </Card>
                </div>
              </CarouselItem>
              <!--刷身份证-->
              <CarouselItem>
                <id-card v-if="stepIndex === 2"  @sure="sureIdCard" @again="backTime = 300" @wzOpen="wzOpen"
                         @wzHide="wzHide" :userId="userId"></id-card>
              </CarouselItem>
              <!--完成-->
              <CarouselItem>
                <div v-if="stepIndex === 3">
                  <div class="rzr-success-wrapper">
                    <div>
                      <img src="../../assets/sucess.png">
                      <h4 style="margin: 30px 0;font-size: 30px">添加成功</h4>
                      <div style="margin-top: 60px">
                        <Button type="primary" long size="large" @click="back">点击完成</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </CarouselItem>
            </Carousel>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import loadingPage from  "../loadingPage/loadingPage"
  import continueModel from "../continueModel/continueModel"
  import searchObjWS from "../searchObjWS/searchObjWS"
  import guide from "../guide/guide"
  import {getLocalStorage, setLocalStorage} from "../../common/js/localStorage"
  import {baseUrl} from "../../common/js/request"
  import QRCode from 'qrcode'
  import IdCard from "../idCard/idCard";

  export default {
    name: "RZR",
    components: {
      IdCard,
      loadingPage,
      continueModel,
      guide,
      searchObjWS
    },
    data() {
      return {
        hotelId: null,
        steps: [
          {
            id: 0,
            title: '进行中',
            content: '扫描二维码'
          },
          {
            id: 1,
            title: '待进行',
            content: '选择订单'
          },
          {
            id: 2,
            title: '待进行',
            content: '补录入住人'
          },
          {
            id: 3,
            title: '待进行',
            content: '完成'
          }
        ],
        stepIndex: 0,
        orderObj: {},
        backTime: 300,
        intervalTime: null,
        orderArr: [],
        loadingInit: true,
        userId: 0,
        userOpenId: 0,
        phone: '',
        index: 0,
        idCardArr: []
      }
    },
    methods: {
      /*返回首页*/
      back() {
        this.stepIndex = 0
        this.steps[this.stepIndex].title = "进行中"
        this.steps[this.stepIndex + 1].title = "待进行"
        clearInterval(this.intervalTime)
        this.$emit('back')
      },
      /*获取userObj*/
      gerUserObj(user) {
        this.userId = user.userId
        this.userOpenId = user.userOpenId
        this.phone = user.phone
        this.selectOrder()
      },
      /*点击忘证入住*/
      wzOpen() {
        this.backTime = 300
      },
      /*关闭忘证入住*/
      wzHide() {
        this.backTime = 300
      },
      /*确定身份证核验*/
      sureIdCard(val) {
        this.idCardArr = val
        this.backTime = 300
        this.bindingGuest()
      },
      /*绑定入住人*/
      bindingGuest() {
        let list = []
        this.idCardArr.forEach((item) => {
          list.push(item.idCardDto)
        })
        let obj = {
          cardDtoList: list,
          masterId: this.orderObj.masterId
        }
        let self = this
        this.$http.post('/order/bind/user/guest',obj).then(res => {
          self.backTime = 60
          self.stepIndex = 3
          self.steps[self.stepIndex].title = "进行中"
          self.steps[self.stepIndex + 1].title = "待进行"
        })
      },
      //选择订单
      activeOrder(val) {
        this.orderObj = val
        this.stepIndex = 2
        this.steps[this.stepIndex].title = "进行中"
        this.steps[this.stepIndex + 1].title = "待进行"
      },
      //查询订单
      selectOrder() {
        let self = this
        self.orderArr = []
        let obj = {
          hotelId: this.hotelId,
          orderType: 0,
          orderStatus: 2,
          queryType: 0,
          queryValue: this.userId
        }
        this.$http.get('/order/custom/query/user/record', {
          params: obj
        }).then(res => {
          res.result.forEach((item) => {
            self.orderArr.push(item)
          })
          self.orderArr.forEach((item) => {
            item.inUserDto = JSON.parse(item.inUserDto)
            item.roomInfoDto = JSON.parse(item.roomInfoDto)
            item.roomTypeDto = JSON.parse(item.roomTypeDto)
          })
          if(self.orderArr.length === 0) {
            self.$Modal.error({
              title: '没有找到订单',
              content: '没有找到订单,请核实',
              onOk: () => {
                self.back()
              }
            });
          } else {
            self.stepIndex = 1
            self.steps[self.stepIndex].title = "进行中"
            self.steps[self.stepIndex + 1].title = "待进行"
          }
        })
      },
      /*页面倒计时*/
      pageOutTime() {
        this.intervalTime = setInterval(() => {
          this.backTime--
          if(this.backTime <= 0) {
            clearInterval(this.intervalTime)
            this.stepIndex = 0
            this.modalOut = false
            this.steps[0].title = "进行中"
            this.steps[1].title = "待进行"
            this.steps[2].title = "待进行"
            this.steps[3].title = "待进行"
            this.$emit('back')
          }
        }, 1000)
      }
    },
    created() {
      let self = this
      setTimeout(() => {
        self.hotelId = getLocalStorage("zs-offline-hotelId")
        self.backTime = 300
        self.pageOutTime()
      }, 200)
      setTimeout(() => {
        self.loadingInit = false
      }, 1000)
    },
    filters: {
      goodOverFlow(val) {
        let v = parseFloat(val)
        return v.toFixed(2)
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalTime)
      this.loadingInit = true
    }
  }
</script>

<style scoped lang="stylus">
  .popup-bottom
    bottom 0
    left 0
    width 100%
    height 100%
    position fixed
    font-size 15px
    background-color #fff
    z-index 999
    overflow hidden
    .popup-bottom-title
      width 100%
      padding 18px 22px
      display flex
      background-color #ffffff
      border-bottom 1px solid #dcdee2
      .popup-bottom-title-steps
        width 100%
        display flex
        align-items center
        justify-content center
    .popup-bottom-body
      top 81px
      left 0
      width 100%
      height 100%
      position absolute
      overflow auto
  .popup-bc
    top 0
    left 0
    width 100%
    height 100%
    position fixed
    z-index 998
    background-color rgba(0, 0, 0, .6)
  .outTime
    margin 32px 0
    text-align center
    font-size 22px
  .time-button
    margin-top 24px
    padding-bottom 81px
    width 100%
    display flex
    align-items center
    justify-content center
  .type-item-wrapper
    padding 24px 60px
    display flex
    align-items center
    justify-content space-around
    flex-wrap wrap
    .type-item
      display flex
      align-items center
      justify-content flex-start
      .type-item-left
        width 168px
        height 168px
        margin-right 18px
        position relative
        overflow hidden
        border-radius 6px
        > img
          height 168px
        .type-item-surplus
          bottom 0
          left 0
          width 100%
          position absolute
          padding 8px
          background-color rgba(0,0,0, .5)
          color #ffffff
          text-align center
          font-size 14px
        .type-item-surplus-no
          bottom 0
          left 0
          width 100%
          position absolute
          padding 8px
          color #808695
          text-align center
          font-size 14px
          background-color rgba(197,200,206, .9)
    .type-item-right
      width calc(100% - 186px)
      height 100%
      h4
        margin-bottom 12px
        font-size 18px
      .price-day
        margin-top 12px
        display flex
        justify-content flex-end
        text-align right
        font-size 22px
        h5
          color #ed4014
          font-size 22px
  .room-div
    margin 24px
    padding 12px 24px
    background-color #2d8cf0
    border-radius 4px
    color #ffffff
  .order-item
    width 100%
    display flex
    align-items center
    justify-content space-between
    color #808695
    > span
      color #515a6e
  .time-wrapper
    width 100%
    display flex
    align-items flex-start
    justify-content center
    .time-left
      width 20%
      height 100vh
      border-right: 1px solid #e8eaec
      > h4
        margin-top 32px
        text-align center
        font-size 22px
      .time-action
        width 100%
        height calc(100% - 200px)
        display flex
        align-items center
        .time-action-item
          width 150px
          height 150px
          margin 0 auto
          display flex
          align-items center
          justify-content center
          border-radius 50%
          background-color #808695
          color #ffffff
          font-size 22px
        .time-action-item-yes
          background-color #2d8cf0 !important
  .zh-type-wrapper
    display flex
    align-items center
    justify-content center
    .zh-type
      height calc(100vh - 81px)
      width 50%
      display flex
      align-items center
      justify-content center
      text-align center
      img
        width 100px
      h4
        margin 20px 0
        font-size 18px
        font-weight bold
      p
        font-size 14px
        font-weight 400
      .zh-type-item
        min-width 350px
  .order-list-wrapper
    margin-top 5%
    padding 0 40px
    display flex
    align-items center
    justify-content space-between
    flex-wrap wrap
    .order-list
      width 400px
      padding 15px
      display flex
      align-items center
      justify-content space-between
      h4
        font-size 18px
  .popup-bottom-time
    font-size 22px
    line-height 44px
    font-weight 600
  .idCard-wrapper
    width 100%
    height 687px
    padding 24px
    overflow hidden
    > h4
      margin-top 24px
      font-size 28px
      text-align center
  .idCard-img-wrapper
    margin-top 24px
    display flex
    align-items center
    justify-content center
    .idCard-img
      width auto
      height 450px
  .tf-label
    margin-top 6px
    font-size 14px
    color #ff9900
  .idCard-submit
    margin-top 40px
    display flex
    align-items center
    justify-content center
  .rzr-success-wrapper
    padding 100px
    display flex
    align-items center
    justify-content center
    text-align center
    img
      width 200px;
  .popupBottomMove-enter-active, .popupBottomMove-leave-active
    transition all .4s
  .popupBottomMove-enter, .popupBottomMove-leave-to
    transform translateY(100%)
  .popupBottomShow-enter-active, .popupBottomShow-leave-active
    transition all .4s
    opacity 1
  .popupBottomShow-enter, .popupBottomShow-leave-to
    opacity 0
  .moveTop-enter-active, .moveTop-leave-active
    transition all .4s
    opacity 1
  .moveTop-enter, .moveTop-leave-to
    transform translateY(100%)
    opacity 0
</style>
