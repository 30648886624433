<template>
  <div>
    <div class="calendar-action">
      <Button type="primary" size="large" @click="setDateData(1)"  style="border-radius: 0 6px 6px 0">
        <Icon type="ios-arrow-back"></Icon>
        上个月
      </Button>
      <Button type="primary" size="large" @click="setDateData(2)" style="border-radius: 6px 0 0 6px">
        下个月
        <Icon type="ios-arrow-forward"></Icon>
      </Button>
    </div>
    <div class="weekDesc">
      <ul>
        <li style="color: #ff9900">日</li>
        <li>一</li>
        <li>二</li>
        <li>三</li>
        <li>四</li>
        <li>五</li>
        <li style="color: #ff9900">六</li>
      </ul>
    </div>
    <div class="wrapper" ref="wrapper">
      <div class="priceDetail">
        <div v-for="(item, index) in calendarData">
          <template v-if="index === cindex">
            <div class="calendarCont">
              <div class="calendarYearMonth">{{item.year}}年&nbsp{{item.month}}月</div>
              <div class="calendarDate">
                <ul>
                  <div style="position: relative;width: 14.2%" v-for="o in item.occupied">
                    <li class="calendarDate-li"></li>
                  </div>
                  <div style="position: relative;width: 14.2%" v-for="(day, index2) in item.days">
                    <p v-show="labelIndex1 == index && labelIndex2 == index2 && labelNumber" class="hint--top" :data-hint="'共'+labelNumber+'天'"></p>
                    <li class="calendarDate-li" v-bind:class="{ equal: day.compareToday=='equal',
                             'less': day.compareToday=='less',
                             'greater': day.compareToday=='greater',
                             'selectStart':((item.year+'-'+item.month+'-'+day.date)==selectStartTime),
                             'selectEnd':((item.year+'-'+item.month+'-'+day.date)==selectEndTime),
                             'selectStartAndEnd': ((item.year+'-'+item.month+'-'+day.date)==selectStartTime && (item.year+'-'+item.month+'-'+day.date)==selectEndTime),
                             'selectStartBetween': betweenStartEnd((item.year+'-'+item.month+'-'+day.date), selectStartTime, selectEndTime)
                             }"
                        @click="selectDateEvent(item.year, item.month, day.date, index, index2)">
                      <p class="calendarDateDay">
                        {{day.date}}
                      </p>
                      <p class="calendarDate-li-p">
                        <span v-if="day.compareToday === 'equal'">入住</span>
                        <span v-if="day.compareToday === 'equal' && labelIndex2 === index2">/</span>
                        <span v-if="labelIndex2 === index2">离店</span>
                      </p>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </template>
        </div>
        <div style="height: 20px"></div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "calendar",
    data() {
      return {
        cindex: 0,
        calendarData: [],
        selectStatus: 0, // -1:选择完成,0未选择任何，1:选择了开始时间
        selectStartTime: null,
        selectEndTime: null,
        today: null,
        toMonth: null,
        labelNumber: 0,
        startNumber: {
          i1: 0,
          i2: new Date().getDate() - 1
        },
        endNumber: {
          i1: 0,
          i2: 0
        },
        labelIndex1: 0,
        labelIndex2: 0
      }
    },
    created() {
      let self = this
      setTimeout(() => {
        let date = new Date()
        let year = date.getFullYear()
        let month = date.getMonth()
        let day = date.getDate()
        this.calendarData = this.createCalendarData(year + '-' + month, 12)
        let mm = parseInt(month) + 1
        let dd = parseInt(day) + 1
        this.selectStartTime = (year + '-' + mm + '-' + day)
        this.selectDateEvent(year, mm, day)
        this.selectEndTime = ''
        this.today = day
        this.toMonth = month + 1
        this.labelIndex1 = 0
        this.labelIndex2 = day
        this.labelNumber = 0
      }, 200)
    },
    methods: {
      /*选择月份*/
      setDateData(val) {
        if (val === 1) {
          if(this.cindex < 0) {
            this.$Message.error('不能选择之前的时间')
            return
          } else {
            if(this.cindex === 0) {
              this.$Message.error('不能选择之前的时间')
              return
            } else {
              this.cindex--
            }
          }
        }
        if (val === 2) {
          if(this.cindex > 11) {
            this.$Message.error('不能超过12个月')
            return
          } else {
            if(this.cindex === 11) {
              this.$Message.error('不能超过12个月')
              return
            } else {
              this.cindex++
            }
          }
        }
      },
      /**
       * 构建日历数据
       *
       * @param startYearMonth 开始年月
       * @param step 获取几个月的
       * @returns {Array}
       */
      createCalendarData(startYearMonth, step) {
        // 由于JavaScript中day的范围为1~31中的值，所以当设为0时，会向前 一天，也即表示上个月的最后一天。
        let rs = []
        let year = startYearMonth.split("-")[0]
        let month = startYearMonth.split("-")[1]
        month = parseInt(month)
        year = parseInt(year)
        let todayDateObj = new Date()
        for (let i = 0; i < step; i++) {
          // 获取当月的信息
          let nowMonthDate = new Date(year, month + i, 1)
          let occupiedLength = nowMonthDate.getDay()
          let occupied = []
          for (let ii = 0; ii < occupiedLength; ii++) {
            occupied.push(ii)
          }
          // 判断这个月有多少天(既下个月的第0天 也即表示上个月的最后一天。)
          let d = new Date(nowMonthDate.getFullYear(), (nowMonthDate.getMonth() + 1), 0)
          let maxDay = d.getDate()
          // 判断周数 向上取整，不要做小数相除如：2.1/0.7
          let totalWeek = Math.ceil(maxDay / 7)
          let daysDesc = []
          let nowYearDesc = nowMonthDate.getFullYear()
          // Date对象月份从0开始，即0表示1月份，以此类推
          let nowMonthDesc = nowMonthDate.getMonth() + 1
          let compareToday = null
          for (let j = 1; j <= maxDay; j++) {
            // 获取当天是星期几
            let nowDay = new Date(year, month + i, j)
            let nowDateObj = new Date(year, month + i, j)
            if (nowDateObj.toDateString() == todayDateObj.toDateString()) {
              compareToday = 'equal'
            } else if (nowDateObj < todayDateObj) {
              compareToday = 'less'
            } else {
              compareToday = 'greater'
            }
            daysDesc.push({'date': nowDay.getDate(), 'day': nowDay.getDay(), 'compareToday': compareToday, price: 0})
          }

          let calendar = {
            'year': nowYearDesc,
            'month': nowMonthDesc,
            'days': daysDesc,
            'occupied': occupied,
            'lastDate': maxDay,
            'weeks': totalWeek
          }
          rs.push(calendar)
        }
        return rs;
      },
      // selectStatus: //-1:设置开始时间 0未选择任何，1:选择完毕
      // 先点击开始，再点击结束
      // 如果已经选择了开始和结束，再点任何一天都会变成开始时间
      // 如果选择了开始时间，再次点击的时间如果比当前时间小,则这天为开始时间
      // 选择了开始时间和结束时间为一天，再点击当日，变成开始时间
      /**
       * 选择日历事件
       *
       * @param year
       * @param month
       * @param date
       */
      selectDateEvent(year, month, date, index, index2) {
        console.log(this.selectStatus)
        console.log(111)
        this.labelIndex1 = index
        this.labelIndex2 = index2
        this.labelNumber = index2
        // 如果传递时间比当前时间小，则不做处理
        let todayDateObj = new Date();
        let todayYear = todayDateObj.getFullYear()
        let todayMonth = todayDateObj.getMonth() + 1
        let todayDate = todayDateObj.getDate()
        if (new Date(year, month, date).getTime() < new Date(todayYear, todayMonth, todayDate).getTime()) {
          // console.log('选择时间小于当前时间，不处理')
          return
        }
        console.log(this.selectStatus)
        switch (this.selectStatus) {
          case -1:
            this.endNumber.i1 = index
            this.endNumber.i2 = index2
            let hadStartTime1 = this.selectStartTime
            if (hadStartTime1 == null) {
              this.selectEndTime = null
              this.selectStatus = 0
              break
            }
            // 如果相等，赋予特殊标示（开始结束日期为同一天）
            if (this.selectStartTime == (year + '-' + month + '-' + date)) {
              this.labelNumber = 0
            } else {
              let startYear = hadStartTime1.split('-')[0]
              let startMonth = hadStartTime1.split('-')[1]
              let startDate = hadStartTime1.split('-')[2]
              // 如果设置的时间小于开始时间，重置开始时间
              let a = new Date(year + '-' + month + '-' + date + ' 00:00').getTime()
              let b = new Date(startYear + '-' + startMonth + '-' + startDate + ' 00:00').getTime()
              if (a < b) {
                this.selectStartTime = (year + '-' + month + '-' + date)
                this.selectEndTime = null
                this.selectStatus = 1
                this.startNumber.i1 = index
                this.startNumber.i2 = index2
                this.labelNumber = 0
                // console.log('重置开始时间')
                break
              }
            }
            // 存在判断是否
            this.selectEndTime = (year + '-' + month + '-' + date)
            this.selectStatus = -1
            let ii1 = this.startNumber.i1
            let ii2 = this.endNumber.i1
            let ll = 0
            if (ii2 > ii1) {
              /*跨月*/
              for (ii1; ii1 < ii2; ii1++) {
                ll += this.calendarData[ii1].days.length
              }
              console.log(l)
              let ie = ll + this.endNumber.i2
              this.labelNumber = ie - this.startNumber.i2
            } else {
              /*不跨月*/
              this.labelNumber = this.endNumber.i2 - this.startNumber.i2
            }
            this.$emit('selectTime', this.dateFtt(this.selectStartTime), this.selectStartTime, this.dateFtt(this.selectEndTime), this.selectEndTime, this.labelNumber)
            break
          case 0:
            this.selectStartTime = (year + '-' + month + '-' + date)
            this.selectStatus = 1
            // console.log('设置开始时间')
            //this.$emit('selectTime', this.dateFtt(this.selectStartTime), this.selectStartTime, this.dateFtt(this.selectEndTime), this.selectEndTime, this.labelNumber)
            break
          case 1:
            // 如果点击这个按钮则取消
            // 判断是否存在开始结束时间
            this.endNumber.i1 = index
            this.endNumber.i2 = index2
            let hadStartTime = this.selectStartTime
            if (hadStartTime == null) {
              this.selectEndTime = null
              this.selectStatus = 0
              break
            }
            // 如果相等，赋予特殊标示（开始结束日期为同一天）
            if (this.selectStartTime == (year + '-' + month + '-' + date)) {
              this.labelNumber = 0
            } else {
              let startYear = hadStartTime.split('-')[0]
              let startMonth = hadStartTime.split('-')[1]
              let startDate = hadStartTime.split('-')[2]
              // 如果设置的时间小于开始时间，重置开始时间
              let a = new Date(year + '-' + month + '-' + date + ' 00:00').getTime()
              let b = new Date(startYear + '-' + startMonth + '-' + startDate + ' 00:00').getTime()
              if (a < b) {
                this.selectStartTime = (year + '-' + month + '-' + date)
                this.selectEndTime = null
                this.selectStatus = 1
                this.startNumber.i1 = index
                this.startNumber.i2 = index2
                this.labelNumber = 0
                // console.log('重置开始时间')
                break
              }
            }
            // 存在判断是否
            this.selectEndTime = (year + '-' + month + '-' + date)
            this.selectStatus = -1
            let i1 = this.startNumber.i1
            let i2 = this.endNumber.i1
            let l = 0
            console.log(i1)
            console.log(i2)
            if (i2 > i1) {
              /*跨月*/
              for (i1; i1 < i2; i1++) {
                l += this.calendarData[i1].days.length
              }
              console.log(l)
              let ie = l + this.endNumber.i2
              this.labelNumber = ie - this.startNumber.i2
            } else {
              /*不跨月*/
              this.labelNumber = this.endNumber.i2 - this.startNumber.i2
            }
            this.$emit('selectTime', this.dateFtt(this.selectStartTime), this.selectStartTime, this.dateFtt(this.selectEndTime), this.selectEndTime, this.labelNumber)
            break
        }
      },
      /**
       * 判断给的日期是否在两个日期之间
       *
       * @param nowTime
       * @param selectStartTime
       * @param selectEndTime
       * @returns {boolean}
       */
      betweenStartEnd(nowTime, selectStartTime, selectEndTime) {
        if (selectStartTime == null || selectEndTime == null) {
          return false;
        }

        let startYear = selectStartTime.split('-')[0];
        let startMonth = selectStartTime.split('-')[1] - 1;
        let startDate = selectStartTime.split('-')[2];
        // let startTime = new Date(startYear, startMonth, startDate);
        let startTime = (new Date(startYear, startMonth, startDate)) / 1000;

        let endYear = selectEndTime.split('-')[0];
        let endMonth = selectEndTime.split('-')[1] - 1;
        let endDate = selectEndTime.split('-')[2];
        // let endTime = new Date(endYear, endMonth, endDate);
        let endTime = (new Date(endYear, endMonth, endDate)) / 1000;

        let selectYear = nowTime.split('-')[0];
        let selectMonth = nowTime.split('-')[1] - 1;
        let selectDate = nowTime.split('-')[2];

        // let selectTime = new Date(selectYear, selectMonth, selectDate);
        let selectTime = (new Date(selectYear, selectMonth, selectDate)) / 1000;
        if (selectTime > startTime && selectTime < endTime) {
          //console.log('here2')
          //console.log(nowTime, selectStartTime, selectEndTime)
          //console.log(selectYear, selectMonth, selectDate)
          //console.log(selectTime, startTime, endTime)

          return true;
        } else {

          return false;
        }
      },
      /*
      * 日期格式转换
      * */
      dateFtt (date) {
        if (!date) {
          return
        }
        let arr = date.split('-')
        let myDate = ''
        arr.forEach((item, index) => {
          if (item < 10) {
            item = '0' + item
          }
          if (index > 1) {
            myDate = myDate + item + '日'
          } else {
            myDate = item + '月'
          }
        })
        return myDate
      }
    },
  }
</script>

<style scoped>
  li {
    list-style-type: none;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .priceDetail {
    width: 100%;
    position: relative;
  }

  .title {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 18px;
    text-align: center;
  }

  .title p {
    width: 100%;
    position: absolute;
  }

  .title-img {
    width: 14.2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .weekDesc {
    width: 100%;
    height: 48px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #17233d;
  }

  .weekDesc-actionLeft {
    top: 0;
    left: 0;
    height: 100%;
    width: 28px !important;
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 28px;
  }

  .weekDesc-actionLeft:hover {
    cursor: pointer;
    color: #ff9900;
  }

  .weekDesc-actionRight {
    top: 0;
    right: 0;
    height: 100%;
    width: 28px !important;
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 28px;
  }

  .weekDesc-actionRight:hover {
    cursor: pointer;
    color: #ff9900;
  }

  .weekDesc ul {
    display: flex;
    width: 100%;
  }

  .weekDesc ul li {
    width: 14.2%;
    text-align: center;
  }

  .calendarYearMonth {
    line-height: 40px;
    height: 40px;
    letter-spacing: 0.2px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #f8f8f9;
    color: #17233d;
  }

  .calendarDate {
    width: 100%;
    background-color: #ffffff;
  }

  .calendarDate ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .calendarDate ul li {
    height: 53px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: 1px solid #f8f8f9;
    background-color: white;
  }

  .calendarDateDay {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  .calendarDate-li-p {
    width: 100%;
    bottom: 4px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: 12px;
    border-radius: 0 0 0 6px;
  }

  /*1.日历天数选择样式分类*/
  /*已经过期的样式*/
  .calendarDate ul .less {
    color: #c5c8ce;
    pointer-events: none;
  }

  /*选择开始样式*/
  .calendarDate ul .selectStart {
    border-radius: 6px 0 0 6px;
    background-color: #bba37f;
    color: #fff;
  }

  /*选择结束样式*/
  .calendarDate ul .selectEnd {
    border-radius: 0 6px 6px 0;
    background-color: #bba37f;
    color: #fff;
  }

  /*开始和结束一样的样式*/
  .calendarDate ul .selectStartAndEnd {
    border-radius: 6px;
    background-color: #bba37f;
    color: #fff;
  }

  /*开始和结束一样的日期格式*/
  .calendarDate ul .selectStartAndEnd .calendarDateDay {
    color: #fff;
  }

  .calendarDate ul .selectStartBetween {
    color: #ffffff;
    background-color: rgba(187, 163, 127, 0.6);
  }

  /*已经修改过价格的样式*/
  /*2.日历内细分样式*/
  /*标准时间*/
  /*节假日时间*/
  /*价格样式*/
  .split_line {
    height: 10px;
    background-color: #e8eaec;
  }

  /*提示框样式*/
  [class*="hint--"] {
    top: -6%;
    left: 50%;
    position: absolute;
  }

  [class*="hint--"]:before, [class*="hint--"]:after {
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    z-index: 1000000;
  }

  [class*="hint--"]:before {
    content: '';
    position: absolute;
    background: transparent;
    border: 6px solid transparent;
    z-index: 1000001;
  }

  [class*="hint--"]:after {
    background: #383838;
    color: white;
    padding: 8px 10px;
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
  }

  [class*="hint--"][aria-label]:after {
    content: attr(aria-label);
  }

  [class*="hint--"][data-hint]:after {
    content: attr(data-hint);
  }

  .hint--top:before {
    border-top-color: #383838;
  }

  .hint--top:before {
    margin-bottom: -11px;
  }

  .hint--top:before, .hint--top:after {
    bottom: 100%;
    left: 50%;
    border-radius: 4px;
  }

  .hint--top:before {
    left: calc(50% - 6px);
  }

  .hint--top:after {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .calendarDate-li:hover {
    cursor: pointer;
  }

  .greater .calendarDate-li-p {
    color: #ffffff;
  }

  .calendar-action {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
