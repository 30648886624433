<template>
  <div id="app" @contextmenu.prevent @touchmove.prevent>
    <router-view/>
  </div>
</template>

<script>
  import {getLocalStorage} from "./common/js/localStorage"

  export default {
    methods: {
      /*禁止浏览器后退*/
      stopBack() {
        history.pushState(null,null,window.location.href)
        window.addEventListener('popstate', () => {
          history.pushState(null,null,window.location.href)
        })
      },
      // 进入全屏的方法
      FullCreeen() {
        let el = document.documentElement;
        let rfs =
          el.requestFullScreen ||
          el.webkitRequestFullScreen ||
          el.mozRequestFullScreen ||
          el.msRequestFullScreen;
        // Element.requestFullscreen() 此方法用于发出异步请求使元素进入全屏模式。
        // 调用此API并不能保证元素一定能够进入全屏模式。如果元素被允许进入全屏幕模式，
        // document对象会收到一个fullscreenchange事件，通知调用者当前元素已经进入全屏模式。如果全屏请求不被许可，则会收到一个fullscreenerror事件。
        if (typeof rfs != "undefined" && rfs) {
          rfs.call(el);
        } else if (typeof window.ActiveXObject != "undefined") {
          let wscript = new ActiveXObject("WScript.Shell");
          if (wscript != null) {
            wscript.SendKeys("{F11}");
          }
        }
      }
    },
    watch: {
      $route(newPath, oldPath) {
        let token = getLocalStorage('zs-offline-userToken')
        if (!token || typeof (token) === 'undefined') {
          this.$router.push('login')
        }
      }
    },
    mounted () {
      this.stopBack()
      let obj = getLocalStorage('zs-offline-hotelId')
      if (obj && typeof (obj) !== 'undefined') {
        /*if(obj.remember) {
          this.$router.push('/')
        } else {
          this.$router.push('login')
        }*/
        this.$router.push('/')
      } else {
        this.$router.push('login')
      }
      this.FullCreeen()
    }
  }
</script>

<style lang="stylus">
  body
    background-color #f7f7f7
</style>
