<template>
  <transition name="moveTop">
    <div v-show="showFlag" class="advertising-wrapper" @click="hidePage">
     <!-- <video :muted="muted" id="myVideo" :width="w" :height="h" autoplay loop>
        <source src="../../assets/flow.mp4" type="video/mp4">
      </video>-->
    </div>
  </transition>
</template>

<script>
  export default {
    name: "advertising",
    data() {
      return {
        showFlag: false,
        h: 600,
        w: 600,
        myVideo: null,
        muted: false
      }
    },
    methods: {
      hidePage() {
        this.showFlag = false
        this.muted = true
        this.$emit('hidePage')
      },
      showPage() {
        this.showFlag = true
        this.muted = false
      },
      play() {
        this.myVideo.play()
        this.muted = true
      }
    },
    created() {
      let self = this
      setTimeout(() => {
        self.w = document.body.clientWidth
        self.h = document.body.clientHeight
        self.myVideo = document.getElementById("myVideo");
      }, 200)
    }
  }
</script>

<style scoped>
  .advertising-wrapper {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999999;
    background-color: #000;
  }
  .moveTop-enter-active, .moveTop-leave-active {
    transition: all 1s;
    opacity: 1;
  }
  .moveTop-enter, .moveTop-leave-to {
    transform: translateY(100%);
    opacity: 0;
  }
</style>