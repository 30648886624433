<template>
  <div class="loading-page-wrapper">
    <Spin fix>
      <Icon type="ios-loading" size='64' class="demo-spin-icon-load"></Icon>
      <div style="font-size: 18px;margin-top: 15px">Loading</div>
    </Spin>
  </div>
</template>

<script>
  export default {
    name: "loadingPage"
  }
</script>

<style scoped>
  .loading-page-wrapper {
    position: relative;
    width: 100%;
    height: calc(100vh - 81px);
  }
  .demo-spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
  }
  @keyframes ani-demo-spin {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
  }
  .demo-spin-col{
    height: 100px;
    position: relative;
    border: 1px solid #eee;
  }
</style>