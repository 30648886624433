<template>
  <div>
    <!--语音播报 扫描二维码-->
    <audio autoplay="autoplay" v-show="false">
      <source src="../../assets/audio/code.mp3" type="audio/mpeg">
    </audio>
    <div class="code-saoyisao-wrapper">
      <img class="code-saoyisao" src="../../assets/codesaoyisao.png">
    </div>
    <div v-if="orderType === 1" class="code-wrapper">
      <Card>
        <img class="code-img" src="../../assets/code.png">
      </Card>
    </div>
    <div v-if="orderType !== 1" class="code-wrapper">
      <Card>
        <img class="code-img" :src="ImgN + codeImg">
      </Card>
    </div>
    <h4 class="code-h4">请使用微信(扫一扫功能)扫描二维码</h4>
    <div v-if="showRZ" class="code-button">
      <Button type="success" size="large" @click="goGuide">查看入住指引</Button>
    </div>
  </div>
</template>

<script>

  export default {
    name: "wxCode",
    props: {
      orderType: Number,
      orderId: Number,
      showRZ: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        codeImg: null
      }
    },
    methods: {
      getCode() {
        this.$Message.loading({
          content: 'Loading...',
          duration: 0
        })
        let self = this
        let params = new URLSearchParams()
        params.append('order_id', this.orderId)
        params.append('type', this.orderType)
        params.append('is_pay', 0)
        this.$http.post(IN + 'index/img/qrcode', params).then((response) => {
          self.$Message.destroy()
          self.codeImg = response.data
        }).catch(() => {
          self.$Message.destroy()
          self.$Message.error('服务器错误')
        })
      },
      /*查看入住指引*/
      goGuide() {
        this.$emit('goGuide')
      }
    },
    created() {
      let self = this
      setTimeout(() => {
        if(self.orderType === 2 || self.orderType === 3) {
          self.getCode()
        }
      }, 200)
    }
  }
</script>

<style scoped>
  .code-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .code-saoyisao-wrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .code-saoyisao {
    display: block;
    width: 50px;
    height: 50px;
    margin-bottom: 16px;
  }
  .code-img {
    width: 260px;
  }
  .code-h4 {
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
  }

  .code-button {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>