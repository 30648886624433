/* 格式化时间 */
export function formatDate (date) {
  	const year = date.getFullYear()
  	const month = date.getMonth() + 1
  	const day = date.getDate()
  	const hour = date.getHours()
	const minute = date.getMinutes()
  	const second = date.getSeconds()
	return year + '-' + formatNumber(month) + '-' + formatNumber(day) + ' ' + formatNumber(hour) + ':' + formatNumber(minute) + ':' + formatNumber(second)
}
// 补0
export function formatNumber(n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}

// 计算时间天数
export function calculationDays(firstDate,lastDate) {
	let lastTime = lastDate.getTime()
	let firstTime = firstDate.getTime()
	return Math.ceil((lastTime - firstTime) / 86400000)
}

// 是否是当天
export function ifNowDay(date) {
	let getDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
	let nowDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()
	let flag = 1
	if(getDate === nowDate) {
		flag = 0
	}
	return flag
}
