import Vue from 'vue'
import "../src/common/css/common.css"
import "../src/common/css/myTheme.less"
import 'view-design/dist/styles/iview.css';
import ViewUI from 'view-design'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import {baseRequest} from "./common/js/axios-cfg";
import {ResError} from "./common/js/ResError";
import VueJsonp from 'vue-jsonp'

Vue.use(ViewUI,axios)

Vue.use(VueJsonp)

/*配置axios全局*/
Vue.prototype.$http = baseRequest;

ViewUI.Message.config({
  duration: 4
});

import * as filters from './filters' // 全局过滤器
// 全局注册过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

const errorHandler = (error, vm)=>{
  ViewUI.Message.destroy()
  if(!(error instanceof ResError)){
    ViewUI.Message.error("系统出了点小差，请联系管理员修复一下~");
    console.error(error)
  }else{
    ViewUI.Message.error(error.message);
  }
}

Vue.config.errorHandler = errorHandler;
Vue.prototype.$throw = (error)=> errorHandler(error,this);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
