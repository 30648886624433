<template>
  <div>
    <!--语音播报 扫描二维码-->
    <audio autoplay="autoplay" v-show="false">
      <source src="../../assets/audio/saoyisao.mp3" type="audio/mpeg">
    </audio>
    <div class="code-saoyisao-wrapper">
      <img class="code-saoyisao" src="../../assets/codesaoyisao.png">
    </div>
    <div class="code-wrapper">
      <Card>
        <div class="code-card-wrapper">
          <img class="code-img" :src="wxCodeImg">
          <div v-if="codeMaskFlag" class="code-mask">等待授权</div>
        </div>
      </Card>
    </div>
    <h4 class="code-h4">请使用微信(扫一扫功能)扫描二维码</h4>
  </div>
</template>

<script>
  import {getLocalStorage} from "../../common/js/localStorage"
  import {baseUrl} from "../../common/js/request"
  import axios from "axios"

  export default {
    name: "searchObjWS",
    data() {
      return {
        codeMaskFlag: false,
        hotelId: 0,
        userArr: [],
        wxCodeImg: null,
        socket: null,
        wsHeart: null
      }
    },
    methods: {
      getCode() {
        let self = this
        let obj = {
          hotelId: this.hotelId
        }
        this.$http.postFromData(baseUrl.dev + '/wx/mini/create/unlimit/wx/qrcode', {
          path: 'pages/searchOrderWebSocket/searchOrderWebSocket',
          scene: JSON.stringify(obj)
        }).then(res => {
          self.wxCodeImg = res
          self.createWS()
        })
      },
      /*建立websocket连接*/
      createWS() {
        if(typeof(WebSocket) === "undefined"){
          alert("您的浏览器不支持socket")
        }else{
          // 实例化socket
          this.socket = new WebSocket(baseUrl.wss)
          // 监听socket连接
          this.socket.onopen = this.WSOpen
          // 监听socket错误信息
          this.socket.onerror = this.WSError
          // 监听socket消息
          this.socket.onmessage = this.WSMessage
          this.socket.onclose = () => {
            console.log('websocket关闭了')
          }
        }
      },
      //ws注册
      registerWS() {
        let obj = {
          id: this.hotelId,
          sendDevice: "PC_CLIENT",
          acceptDevice: "MA_CLIENT",
          cmd: "ws_register",
          data: ""
        }
        this.socket.send(JSON.stringify(obj))
        this.sendHeartWS()
      },
      //ws心跳
      sendHeartWS() {
        this.wsHeart = setInterval(() => {
          let obj = {
            id: this.hotelId,
            sendDevice: "PC_CLIENT",
            acceptDevice: "MA_CLIENT",
            cmd: "ws_heart",
            data: ""
          }
          this.socket.send(JSON.stringify(obj))
        }, 30000)
      },
      // 监听socket消息
      WSMessage(res) {
        console.log('监听消息')
        const message = JSON.parse(res.data)
        //微信已扫码 显示遮罩层
        if(message.cmd === 'scan_connect') {
          this.codeMaskFlag = true
        }
        //微信已扫码 取消扫码
        if(message.cmd === 'scan_cancel') {
          this.closeWS()
          this.$emit('back')
        }
        //微信已点击授权
        if(message.cmd === 'scan_success') {
          this.codeMaskFlag = false
          this.sendUseObj(JSON.parse(message.content))
          this.closeWS()
        }
      },
      /* 监听socket连接*/
      WSOpen() {
        console.log('连接成功')
        this.registerWS()
      },
      //关闭链接
      closeWS() {
        let obj = {
          id: this.hotelId,
          sendDevice: "PC_CLIENT",
          acceptDevice: "MA_CLIENT",
          cmd: "pc_close_ma",
          data: ""
        }
        if(this.socket) {
          this.socket.close()
          this.socket.send(JSON.stringify(obj))
        }
        clearInterval(this.wsHeart)
        console.log('关闭ws')
      },
      // 监听socket错误信息
      WSError() {
        console.log('连接失败')
      },
      /*发送USEROBJ*/
      sendUseObj(userObj) {
        this.$emit('sendUseObj',userObj)
      }
    },
    created() {
      let self = this
      setTimeout(() => {
        self.hotelId = getLocalStorage('zs-offline-hotelId')
        self.getCode()
      }, 200)
    },
    beforeDestroy() {
      this.closeWS()
    }
  }
</script>

<style scoped>
  .code-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .code-card-wrapper {
    position: relative;
  }
  .code-saoyisao-wrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .code-saoyisao {
    display: block;
    width: 50px;
    height: 50px;
    margin-bottom: 16px;
  }
  .code-img {
    width: 260px;
  }
  .code-h4 {
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
  }
  .code-mask {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0, 1);
    color: #ffffff;
    font-size: 22px;
    border-radius: 4px;
  }

  .noSelf-button {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
