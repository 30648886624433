export function calculationDateForTime(startDateFormat, endDateFormat) {
  let startTime = startDateFormat.getTime()
  let endTime = endDateFormat.getTime()
  let endTimeNext = endTime + 86400000
  let dateList = []
  let dateArr = []
  for (let i = startTime; i <= endTimeNext; i = i + 86400000) {
    let dateFormatNew = new Date(i)
    dateList.push(dateFormatNew)
  }
  dateList.forEach((dateFormatNew,index) => {
    let obj = {}
    let startDateIF = startDateFormat.getMonth() + startDateFormat.getDate()
    let endDateIF = endDateFormat.getMonth() + endDateFormat.getDate()
    if (startDateIF != endDateIF) {
      if (index == 0) {
        obj.time = dateFormatNew.getFullYear() + '-' + repairZero((dateFormatNew.getMonth() + 1)) + '-' + repairZero(dateFormatNew.getDate())
        obj.hourNum = (24 - startDateFormat.getHours())
      } else if (index + 1 == dateList.length) {
        obj.time = dateFormatNew.getFullYear() + '-' + repairZero((dateFormatNew.getMonth() + 1)) + '-' + repairZero(dateFormatNew.getDate())
        obj.hourNum = endDateFormat.getHours()
      } else {
        obj.time = dateFormatNew.getFullYear() + '-' + repairZero((dateFormatNew.getMonth() + 1)) + '-' + repairZero(dateFormatNew.getDate())
        obj.hourNum = 24
      }
      dateArr.push(obj)
    } else {
      let nowDateIF = dateFormatNew.getMonth() + dateFormatNew.getDate()
      if (startDateIF == nowDateIF) {
        obj.time = dateFormatNew.getFullYear() + '-' + repairZero((dateFormatNew.getMonth() + 1)) + '-' + repairZero(dateFormatNew.getDate())
        obj.hourNum = endDateFormat.getHours() - startDateFormat.getHours()
        dateArr.push(obj)
      }
    }
  })
  return dateArr
}

export function repairZero(val) {
  if (val < 10) {
    val = '0' + val
  }
  return val
}

export function calculationHours(startDateFormat, endDateFormat) {
  let startTime = startDateFormat.getTime()
  let endTime = endDateFormat.getTime()
  let hours = (endTime - startTime) / 3600000
  return hours
}