<template>
  <div class="timeModel-wrapper">
    <div class="timeModel-left">
      <div class="timeModel-left-time">
        <div class="timeModel-left-time-p">
          <p>您当前订单离店时间为:</p>
          <p style="color: #ed4014">{{ endTime }}</p>
          <p>续房费用 <span style="color: #ed4014">￥{{ price | priceFilter }}</span></p>
          <div style="display: flex;align-items: center;justify-content: center;margin-top: 30px">
            <Button style="margin-right: 30px" type="info" size="large" @click="aliPay" :loading="buttonLoading">支付宝支付
            </Button>
            <Button type="success" size="large" @click="wxPay" :loading="buttonLoading">微信支付</Button>
          </div>
        </div>
      </div>
    </div>
    <div class="timeModel-right">
      <h4>选择离店时间</h4>
      <div>
        <template>
          <div v-if="todayArr.length === 0 && tomorrowArr.length !== 0">
            <h4 class="timeModel-right-h4">钟点房无法续房,请选择全日房</h4>
          </div>
          <div v-if="tomorrowArr.length === 0 && todayArr.length !== 0">
            <h4 class="timeModel-right-h4">全日房无法续房,请选择钟点房</h4>
          </div>
          <div v-if="todayArr.length === 0 && tomorrowArr.length === 0">
            <h4 class="timeModel-right-h4" style="font-size: 20px;color: #ed4014">
              当前房间已被预订,如需续房请联系商家:{{ hotelPhone }}</h4>
          </div>
        </template>
        <div v-if="todayArr.length !== 0">
          <h4 class="timeModel-right-h4">钟点房 ({{ todayFont | timeFilter }})</h4>
          <Button :type="actionIndex.one === 1 && actionIndex.two === index ? 'error' : 'primary'" size="large"
                  style="margin: 12px 12px 12px 0" v-for="(item,index) in todayArr" @click="sureNow(item,index)">
            {{ item }}
          </Button>
        </div>
        <div v-if="tomorrowArr.length !== 0">
          <h4 class="timeModel-right-h4" style="margin-bottom: 10px">全日房</h4>
          <div v-for="(item,index) in tomorrowArr">
            <Button :type="actionIndex.one === 2 && actionIndex.two === index ? 'error' : 'primary'" size="large"
                    style="margin: 12px 12px 12px 0" @click="sureNext(item,index)">{{ item | dayFilter }}
            </Button>
          </div>
        </div>
      </div>
    </div>
    <!--微信支付-->
    <Modal v-model="flagObj.wx" title="微信支付" :mask-closable="false" @on-cancel="closeWxPay">
      <canvas ref="qrccodeCanvas" style="display: none"></canvas>
      <div class="pay-img">
        <div>
          <div class="pay-img-icon-wrapper">
            <img class="pay-img-icon" src="../../assets/wxpay.svg">
          </div>
          <img class="pay-img-qr" :src="wxPayImg" style="height: 300px">
          <p>打开微信扫一扫付款</p>
        </div>
      </div>
      <div slot="footer">
      </div>
    </Modal>
    <!--支付宝支付-->
    <Modal v-model="flagObj.ali" title="支付宝支付" :mask-closable="false" @on-cancel="closeAliPay">
      <canvas ref="qrccodeCanvas" style="display: none"></canvas>
      <div class="pay-img">
        <div>
          <canvas ref="qrccodeCanvas" style="display: none"></canvas>
          <div class="pay-img-icon-wrapper">
            <img class="pay-img-icon" src="../../assets/alipay.svg">
          </div>
          <img class="pay-img-qr" :src="aliPayImg" style="height: 300px">
          <p>打开支付宝扫一扫付款</p>
        </div>
      </div>
      <div slot="footer">
      </div>
    </Modal>
    <!--已退押金 无法办理-->
    <Modal v-model="flagObj.pay" width="540" :closable="false" :mask-closable="false">
      <p slot="header" style="text-align: center;">无法续房</p>
      <h4 v-if="hotelObj" style="font-size: 18px;letter-spacing: 2px;line-height: 150%;padding: 15px">
        当前订单已退押金,无法续房,若要续本房间,请重新下单或请联系商家:{{ hotelObj.hotelServicePhone }}</h4>
      <div slot="footer">
        <Button type="error" size="large" long @click="back">关闭</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {getLocalStorage} from "../../common/js/localStorage";
import {formatDate} from "../../common/js/formatTime"
import {calculationDateForDay} from "../../common/js/calculationDate"
import {calculationDateForTime} from "../../common/js/calculationTime"
import QRCode from 'qrcode'
import {baseUrl} from "../../common/js/request"


export default {
  name: "continueModel",
  data() {
    return {
      todayArr: [],
      tomorrowArr: [],
      todayFont: '',
      tomorrowFont: '',
      actionIndex: {
        one: null,
        two: null
      },
      endTime: '请选择离店时间',
      hotelObj: null,
      hotelId: null,
      hotelPhone: 0,
      orderList: [],
      price: 0,
      flagObj: {
        wx: false,
        ali: false,
        pay: false
      },
      wxPayImg: '',
      aliPayImg: '',
      continueOrderObj: {},
      timeType: 1,
      buttonLoading: false,
      cancelId: 0,
      socket: null,
      wsHeart: null,
      closeList: []
    }
  },
  props: {
    orderObj: Object
  },
  methods: {
    /*查询该房间下的订单*/
    getOrderByRoom() {
      let endTIme = new Date(this.orderObj.orderOutTime.replace(/-/g, "/")).getTime()
      let obj = {
        hotelId: this.hotelId,
        roomInfoId: this.orderObj.roomInfoId,
        startTime: this.orderObj.orderInTime,
        endTime:  formatDate(new Date(endTIme + (86400000 * 7)))
      }
      this.orderList = []
      let self = this
      this.$http.get('/order/users/search/continue/check/room/have/order', {
        params: obj
      }).then(res => {
        res.result.forEach((item) => {
          if (item.orderMasterId !== self.orderObj.id) {
            self.orderList.push(item)
          }
        })
        let closeObj = {
          hotelId: self.hotelId,
          startTime: this.orderObj.orderInTime,
          endTime:  formatDate(new Date(endTIme + (86400000 * 7)))
        }
        self.$http.get('/home/room/close', {
          params: closeObj
        }).then(ree => {
          ree.result.forEach((i) => {
            if(self.orderObj.roomInfoId === i.roomId) {
              self.closeList.push(i)
            }
          })
          self.dayInRoom()
          self.timeInRoom()
        })
      })
    },
    /*获取价格*/
    getPrice() {
      let self = this
      self.price = 0
      if (this.timeType === 2) {
        let obj = {
          date: calculationDateForDay(new Date(this.orderObj.orderOutTime.replace(/-/g, '/')), new Date(this.endTime.replace(/-/g, '/'))),
          roomInfoId: this.orderObj.roomInfoId,
          roomTypeId: this.orderObj.roomTypeId,
          startTime: formatDate(new Date(this.orderObj.orderOutTime.replace(/-/g, '/'))),
          endTime: formatDate(new Date(this.endTime.replace(/-/g, '/'))),
          masterId: this.orderObj.id
        }
        this.$http.post('/order/continue/create/day', obj).then(res => {
          let roomPrice = 0
          res.result.forEach((item) => {
            self.price += roomPrice + item.dayPrice
          })
        })
      }
      if (this.timeType === 1) {
        let obj = {
          timeDates: calculationDateForTime(new Date(this.orderObj.orderOutTime.replace(/-/g, '/')), new Date(this.endTime.replace(/-/g, '/'))),
          roomInfoId: this.orderObj.roomInfoId,
          roomTypeId: this.orderObj.roomTypeId,
          startTime: formatDate(new Date(this.orderObj.orderOutTime.replace(/-/g, '/'))),
          endTime: formatDate(new Date(this.endTime.replace(/-/g, '/'))),
          masterId: this.orderObj.id
        }
        this.$http.post('/order/continue/create/hour', obj).then(res => {
          let roomPrice = 0
          res.result.forEach((item) => {
            self.price += roomPrice + item.dayPrice
          })
        })
      }

    },
    /*确定点击 钟点房*/
    sureNow(val, index) {
      this.actionIndex.one = 1
      this.actionIndex.two = index
      this.endTime =  this.todayFont + ' ' + val
      this.timeType = 1
      this.getPrice()
    },
    /*确定点击 全日房*/
    sureNext(val, index) {
      this.actionIndex.one = 2
      this.actionIndex.two = index
      this.endTime = this.tomorrowFont + ' ' + val
      this.timeType = 2
      this.getPrice()
    },
    /*钟点房可定时间*/
    timeInRoom() {
      let time = new Date(this.orderObj.orderOutTime).getTime()
      let date = new Date(this.orderObj.orderOutTime)
      let hour = new Date(this.orderObj.orderOutTime).getHours() + 1
      let hotelObj = this.hotelObj
      let closeTime = 0
      if(this.closeList.length !== 0) {
        closeTime = new Date(this.closeList[0].dayTime + ' 23:59:59').getTime()
      }
      //判断是否超过最晚退房时间
      if (hour < hotelObj.hotelOutRoomTimeDto.hourTimeLatest && closeTime < time) {
        // 判断是否可预订
        if (this.orderList.length !== 0) {
          let orderTime = new Date(this.orderList[0].orderInTime).getTime()
          if (orderTime <= time) {
            this.todayFont = '钟点房已被预订,无法续房'
          } else {
            for (let i = hour; i < new Date(this.orderList[0].orderInTime).getHours(); i++) {
              let label = i + ':00'
              this.todayArr.push(label)
            }
          }
        } else {
          for (let i = hour; i <= hotelObj.hotelOutRoomTimeDto.hourTimeLatest; i++) {
            let label = i + ':00'
            this.todayArr.push(label)
          }
        }
        this.todayFont = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      } else {
        this.todayFont = '钟点房已被预订,无法续房'
      }
    },
    /*全日房可定时间*/
    dayInRoom() {
      let time = new Date(this.orderObj.orderOutTime).getTime()
      let hotelObj = this.hotelObj
      for (let i = 1; i < 8; i++) {
        let nowDate = new Date(time + i * 86400000)
        let nowTime = new Date(time + i * 86400000).getTime()
        // 判断是否可预订
        if (this.orderList.length !== 0) {
          if (nowTime >= new Date(this.orderList[0].orderInTime).getTime()) {
            break
          }
        }
        // 判断是否关房
        if(this.closeList.length !== 0) {
          let closeTime = new Date(this.closeList[0].dayTime.replace(/-/g, "/") + ' 00:00:00').getTime()
          console.log(closeTime)
          console.log(nowTime)
          if(nowTime >= closeTime) {
            break
          }
        }
        let date = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate()
        if (i === 1) {
          let label = date + ' ' + hotelObj.hotelOutRoomTimeDto.dayTime + ':00'
          this.tomorrowArr.push(label)
        } else {
          let label = date + ' ' + hotelObj.hotelOutRoomTimeDto.dayTime + ':00'
          this.tomorrowArr.push(label)
        }
      }
      if (this.tomorrowArr.length === 0) {
        this.tomorrowFont = '全日房已被预订,无法续房'
      }
    },
    /*初始化*/
    initData() {
      this.todayArr = []
      this.tomorrowArr = []
      this.getOrderByRoom()
    },
    /*微信支付*/
    wxPay() {
      if (this.endTime === '请选择离店时间') {
        this.$Message.error("请选择离店时间")
        return
      }
      let self = this
      self.buttonLoading = true
      this.$http.get('/order/get/master/des?orderId=' + this.orderObj.id).then(res => {
        if (res.result.orderMaster.orderStatus !== 5) {
          if (this.timeType === 2) {
            let obj = {
              date: calculationDateForDay(new Date(this.orderObj.orderOutTime.replace(/-/g, '/')), new Date(this.endTime.replace(/-/g, '/'))),
              roomInfoId: this.orderObj.roomInfoId,
              roomTypeId: this.orderObj.roomTypeId,
              startTime: formatDate(new Date(this.orderObj.orderOutTime.replace(/-/g, '/'))),
              endTime: formatDate(new Date(this.endTime.replace(/-/g, '/'))),
              masterId: this.orderObj.id
            }
            this.$http.post('/order/continue/submit/day', obj).then(res => {
              self.cancelId = res.result
              let codeObj = {
                orderMasterId: obj.masterId,
                type: '2',
                id: res.result,
                hotelId: this.hotelId
              }
              self.$http.postFromData(baseUrl.dev + '/wx/mini/create/unlimit/wx/qrcode', {
                path: 'pages/codePay/codePay',
                scene: JSON.stringify(codeObj)
              }).then(re => {
                self.flagObj.wx = true
                self.buttonLoading = false
                self.wxPayImg = re
                if (!self.socket) {
                  self.createWS()
                }
              })
            })
          }
          if (this.timeType === 1) {
            let obj = {
              timeDates: calculationDateForTime(new Date(this.orderObj.orderOutTime.replace(/-/g, '/')), new Date(this.endTime.replace(/-/g, '/'))),
              roomInfoId: this.orderObj.roomInfoId,
              roomTypeId: this.orderObj.roomTypeId,
              startTime: formatDate(new Date(this.orderObj.orderOutTime.replace(/-/g, '/'))),
              endTime: formatDate(new Date(this.endTime.replace(/-/g, '/'))),
              masterId: this.orderObj.id
            }
            this.$http.post('/order/continue/submit/hour', obj).then(res => {
              self.cancelId = res.result
              let codeObj = {
                orderMasterId: self.orderObj.masterId,
                type: '2',
                id: res.result,
                hotelId: this.hotelId
              }
              self.$http.postFromData(baseUrl.dev + '/wx/mini/create/unlimit/wx/qrcode', {
                path: 'pages/codePay/codePay',
                scene: JSON.stringify(codeObj)
              }).then(re => {
                self.flagObj.wx = true
                self.buttonLoading = false
                self.wxPayImg = re
                if (!self.socket) {
                  self.createWS()
                }
              })
            })
          }
        } else {
          self.flagObj.pay = true
        }
      })
    },
    /*支付宝支付*/
    aliPay() {
      if (this.endTime === '请选择离店时间') {
        this.$Message.error("请选择离店时间")
        return
      }
      let self = this
      self.buttonLoading = true
      this.$http.get('/order/get/master/des?orderId=' + this.orderObj.id).then(res => {
        if (res.result.orderMaster.orderStatus !== 5) {
          if (this.timeType === 2) {
            let obj = {
              date: calculationDateForDay(new Date(this.orderObj.orderOutTime.replace(/-/g, '/')), new Date(this.endTime.replace(/-/g, '/'))),
              roomInfoId: this.orderObj.roomInfoId,
              roomTypeId: this.orderObj.roomTypeId,
              startTime: formatDate(new Date(this.orderObj.orderOutTime.replace(/-/g, '/'))),
              endTime: formatDate(new Date(this.endTime.replace(/-/g, '/'))),
              masterId: this.orderObj.id
            }
            this.$http.post('/order/continue/submit/day', obj).then(res => {
              self.cancelId = res.result
              self.$http.post('/pay/ali/prepay/continue/order?continueId=' + res.result).then(res => {
                self.canvasDiv = self.$refs.qrccodeCanvas
                QRCode.toCanvas(self.canvasDiv, res.result, {
                  width: 300
                }, (error) => {
                  self.flagObj.ali = true
                  self.buttonLoading = false
                  if (error) {
                    self.$Message.error('二维码生成失败')
                  } else {
                    let myCanvas = self.$refs.qrccodeCanvas
                    self.aliPayImg = myCanvas.toDataURL('image/png')
                  }
                })
                self.createWS()
              })
            })
          }
          if (this.timeType === 1) {
            let obj = {
              timeDates: calculationDateForTime(new Date(this.orderObj.orderOutTime.replace(/-/g, '/')), new Date(this.endTime.replace(/-/g, '/'))),
              roomInfoId: this.orderObj.roomInfoId,
              roomTypeId: this.orderObj.roomTypeId,
              startTime: formatDate(new Date(this.orderObj.orderOutTime.replace(/-/g, '/'))),
              endTime: formatDate(new Date(this.endTime.replace(/-/g, '/'))),
              masterId: this.orderObj.id
            }
            this.$http.post('/order/continue/submit/hour', obj).then(res => {
              self.cancelId = res.result
              self.$http.post('/pay/ali/prepay/continue/order?continueId=' + res.result).then(res => {
                self.canvasDiv = self.$refs.qrccodeCanvas
                QRCode.toCanvas(self.canvasDiv, res.result, {
                  width: 300
                }, (error) => {
                  self.flagObj.ali = true
                  self.buttonLoading = false
                  if (error) {
                    self.$Message.error('二维码生成失败')
                  } else {
                    let myCanvas = self.$refs.qrccodeCanvas
                    self.aliPayImg = myCanvas.toDataURL('image/png')
                  }
                })
                self.createWS()
              })
            })
          }
        } else {
          self.flagObj.pay = true
        }
      })
    },
    back() {
      this.flagObj.pay = false
    },
    /*关闭微信支付*/
    closeWxPay() {
      this.flagObj.wx = false
    },
    /*关闭支付宝支付*/
    closeAliPay() {
      this.flagObj.ali = false
    },
    /*建立websocket连接*/
    createWS() {
      if (typeof (WebSocket) === "undefined") {
        alert("您的浏览器不支持socket")
      } else {
        // 实例化socket
        this.socket = new WebSocket(baseUrl.wss)
        // 监听socket连接
        this.socket.onopen = this.WSOpen
        // 监听socket错误信息
        this.socket.onerror = this.WSError
        // 监听socket消息
        this.socket.onmessage = this.WSMessage
        this.socket.onclose = () => {
          console.log('websocket关闭了')
        }
      }
    },
    //ws注册
    registerWS() {
      let obj = {
        id: this.hotelId,
        sendDevice: "PC_CLIENT",
        acceptDevice: "MA_CLIENT",
        cmd: "ws_register",
        data: ""
      }
      this.socket.send(JSON.stringify(obj))
      this.sendHeartWS()
    },
    //ws心跳
    sendHeartWS() {
      this.wsHeart = setInterval(() => {
        let obj = {
          id: this.hotelId,
          sendDevice: "PC_CLIENT",
          acceptDevice: "MA_CLIENT",
          cmd: "ws_heart",
          data: ""
        }
        this.socket.send(JSON.stringify(obj))
      }, 30000)
    },
    /* 监听socket连接*/
    WSOpen() {
      console.log('连接成功')
      this.registerWS()
    },
    //关闭链接
    closeWS() {
      let obj = {
        id: this.hotelId,
        sendDevice: "PC_CLIENT",
        acceptDevice: "MA_CLIENT",
        cmd: "pc_close_ma",
        data: ""
      }
      if(this.socket) {
        this.socket.close()
        this.socket.send(JSON.stringify(obj))
      }
      clearInterval(this.wsHeart)
      console.log('关闭ws')
    },
    // 监听socket消息
    WSMessage(res) {
      console.log('监听消息')
      const message = JSON.parse(res.data)
      //微信已扫码 显示遮罩层
      if(message.cmd === 'order_pay_success') {
        this.closeWS()
        this.flagObj.wx = false
        this.flagObj.ali = false
        this.$emit('payOk')
      }
    }
  },
  created() {
    this.hotelId = getLocalStorage('zs-offline-hotelId')
    this.hotelObj = getLocalStorage('zs-offline-hotelDetailsObj')
    this.hotelPhone = getLocalStorage('zs-offline-hotelInfoObj').hotelServicePhone
    this.hotelObj.hotelOutRoomTimeDto = JSON.parse(this.hotelObj.hotelOutRoomTimeDto)
    this.initData()
  },
  filters: {
    timeFilter(val) {
      let time = val.split(' ')
      time = time[0].split('-')
      time = time[0] + '年' + time[1] + '月' + time[2] + '日'
      return time
    },
    dayFilter(val) {
      let time = val.split(' ')
      let time1 = time[0].split('-')
      time = time1[0] + '年' + time1[1] + '月' + time1[2] + '日' + ' ' + time[1]
      return time
    },
    priceFilter(val) {
      let price = val / 100
      price = price.toFixed(2)
      return price
    }
  },
  beforeDestroy() {
    this.closeWS()
  }
}
</script>

<style scoped lang="stylus">
.timeModel-wrapper
  padding 48px 0
  display flex
  justify-content space-around

  .timeModel-left
    width 50%

    > h4
      font-size 18px
      font-weight 600

    .timeModel-left-time
      width 100%
      height 100%
      display flex
      align-items center
      justify-content center
      font-size 18px
      font-weight 600

  .timeModel-right
    width 50%

    > h4
      font-size 18px
      font-weight 600

    .timeModel-right-h4
      margin-top 15px
      font-size 16px
      font-weight 600

.timeModel-left-time-p
  margin 0 48px
  width 100%

  > p
    padding 15px 0
    width 100%
    text-align center
    letter-spacing 1px

.pay-img
  display flex
  align-items center
  justify-content center

  p
    text-align center
    font-size 18px

  .pay-img-icon-wrapper
    display flex
    align-items center
    justify-content center

    .pay-img-icon
      width 150px
      height auto
</style>