<template>
  <div class="login-wrapper">
    <div class="home-bg">
      <img class="home-bg-img" src="../assets/home.jpg">
      <div class="home-bg-blur"></div>
    </div>
    <div class="login">
      <div class="login-title">
        <img src="../assets/gbLogo.png">
        <h5>燕e住 · 酒店无人化解决方案</h5>
      </div>
      <div class="login-form">
        <Form ref="formInline" :model="formInline" :rules="ruleInline">
          <FormItem prop="phone">
            <Input type="text" size="large" v-model="formInline.phone" placeholder="请输入账号"
                   prefix="ios-contact-outline"></Input>
          </FormItem>
          <FormItem prop="password">
            <Input type="password" size="large" v-model="formInline.password" placeholder="请输入密码"
                   prefix="ios-lock-outline"></Input>
          </FormItem>
          <FormItem>
            <Row>
              <Col span="8" style="text-align: left">
                <Checkbox size="large" v-model="remember">记住密码</Checkbox>
              </Col>
              <Col span="8" offset="8" style="text-align: right">
                <a @click="outFullCreeen">退出全屏</a>
              </Col>
            </Row>
          </FormItem>
          <FormItem>
            <drag-verify :width="dragVerifyWidth"
                         :height="40"
                         text="向右拖动滑块"
                         success-text="成功"
                         ref="Verify"></drag-verify>
          </FormItem>
          <FormItem>
            <Button type="primary" long size="large" @click="handleSubmit('formInline')" :loading="loading">登录</Button>
            <div class="login-gx">
              <Button type="error" @click="update">更新版本</Button>
            </div>
          </FormItem>
        </Form>
      </div>
    </div>
    <div class="login-footer">
      <p>官网 - 小程序 - APP - 后台</p>
      <p>Copyright © 成都掌宿信息科技有限公司</p>
    </div>
    <!--选择酒店-->
    <Modal v-model="selectHotelModel" width="800">
      <p slot="header" style="text-align:center">
        <span>请选择酒店</span>
      </p>
      <div class="login-hotel-wrapper">
        <div v-for="(item,index) in hotelList" :key="index" class="login-hotel-item" @click="goHotel(item)">
          {{item.hotelName}}
        </div>
      </div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
  import dragVerify from 'vue-drag-verify'
  import {setLocalStorage, getLocalStorage} from "../common/js/localStorage"

  export default {
    name: "login",
    data() {
      return {
        loading: false,
        remember: true,
        dragVerifyWidth: 384,
        formInline: {
          phone: '',
          password: ''
        },
        ruleInline: {
          phone: [
            {required: true, message: '请输入手机号', trigger: 'blur'}
          ],
          password: [
            {required: true, message: '请输入密码', trigger: 'blur'}
          ]
        },
        selectHotelModel: false,
        hotelList: []
      }
    },
    methods: {
      // 进入全屏的方法
      FullCreeen() {
        let el = document.documentElement;
        let rfs =
          el.requestFullScreen ||
          el.webkitRequestFullScreen ||
          el.mozRequestFullScreen ||
          el.msRequestFullScreen;
        // Element.requestFullscreen() 此方法用于发出异步请求使元素进入全屏模式。
        // 调用此API并不能保证元素一定能够进入全屏模式。如果元素被允许进入全屏幕模式，
        // document对象会收到一个fullscreenchange事件，通知调用者当前元素已经进入全屏模式。如果全屏请求不被许可，则会收到一个fullscreenerror事件。
        if (typeof rfs != "undefined" && rfs) {
          rfs.call(el);
        } else if (typeof window.ActiveXObject != "undefined") {
          let wscript = new ActiveXObject("WScript.Shell");
          if (wscript != null) {
            wscript.SendKeys("{F11}");
          }
        }
      },
      //退出全屏的方法
      outFullCreeen() {
        let el = document;
        let cfs =
          el.cancelFullScreen ||
          el.webkitCancelFullScreen ||
          el.mozCancelFullScreen ||
          el.exitFullScreen;
        if (typeof cfs != "undefined" && cfs) {
          cfs.call(el);
        } else if (typeof window.ActiveXObject != "undefined") {
          let wscript = new ActiveXObject("WScript.Shell");
          if (wscript != null) {
            wscript.SendKeys("{F11}");
          }
        }
      },
      /*登陆*/
      handleSubmit(name) {
        this.FullCreeen()
        this.loading = true
        this.$refs[name].validate((valid) => {
          if (valid) {
            if (this.$refs.Verify.isPassing) {
              this.login()
            } else {
              this.loading = false
              this.$Message.error('请滑动滑块')
            }
          } else {
            this.loading = false
            this.$Message.error('登录失败');
          }
        })
      },
      /*登录*/
      login() {
        let self = this
        this.$http.post('/account/login/merchant', {
          params: this.formInline
        }).then(res => {
          if(!res.result.hotelInfoList) {
            self.loading = false
            self.$Message.error('该商户没有添加酒店')
          }
          if(res.result.hotelInfoList.length === 1) {
            setLocalStorage('zs-offline-userId', res.result.merchantId)
            setLocalStorage('zs-offline-userToken', res.result.token)
            setLocalStorage('zs-offline-hotelId', res.result.hotelInfoList[0].hotelId)
            setLocalStorage('zs-offline-hotelInfoObj', res.result.hotelInfoList[0])
            self.getHotelInfo(res.result.hotelInfoList[0].hotelId)
          } else {
            self.loading = false
            setLocalStorage('zs-offline-userId', res.result.merchantId)
            setLocalStorage('zs-offline-userToken', res.result.token)
            self.hotelList = res.result.hotelInfoList
            self.selectHotelModel = true

          }
        })
      },
      /*跳转酒店*/
      goHotel(hotel) {
        this.loading = true
        setLocalStorage('zs-offline-hotelId', hotel.hotelId)
        setLocalStorage('zs-offline-hotelInfoObj', hotel)
        this.getHotelInfo(hotel.hotelId)
      },
      /*登陆成功跳转页面*/
      go() {
        setTimeout(() => {
          this.$router.push('/')
        }, 1000)
      },
      /*获取酒店详情信息*/
      getHotelInfo(id) {
        let self = this
        this.$http.get('/home/hotel/des/'+id).then(res => {
          self.loading = false
          setLocalStorage('zs-offline-hotelDetailsObj', res.result)
          self.$Message.success('登录成功')
          self.go()
        })
      },
      /*更新版本*/
      update() {
        location.reload()
      }
    },
    components: {
      dragVerify
    },
    mounted() {
      let self = this
      setTimeout(() => {
        self.dragVerifyWidth = self.$refs.formInline.$el.offsetWidth
      }, 200)
    }
  }
</script>

<style scoped lang="stylus">
  .login-wrapper
    height 100vh
    display flex
    flex-direction column
    overflow auto
    color #ffffff

    .login
      width 384px
      margin 0 auto
      padding 32px 0
      flex 1
      text-align center

      .login-title
        > h5
          padding-top 12px
          padding-bottom 24px
          font-size 16px

    .login-footer
      margin 36px 0 12px 0
      padding 0 16px
      text-align center
      color #ffffff
      font-size 14px

      > p
        margin 12px

  .home-bg
    top 0;
    left 0;
    width 100%;
    height 100%;
    position fixed;
    z-index -1;

    .home-bg-img
      height 100%;
      position fixed;
      z-index -1

    .home-bg-blur
      width 100%;
      height 100%;
      position fixed;
      background-color rgba(0, 0, 0, .6)
      z-index 1

  .login-gx
    margin-top 30px;
    display flex
    justify-content flex-end

  .login-hotel-wrapper
    max-width 800px
    max-height 400px
    display flex
    align-items center
    justify-content flex-start
    flex-wrap wrap
    overflow-y auto
    .login-hotel-item
      margin 12px
      padding 12px
      background-color #bba37f
      color #ffffff
      font-size 18px
      border-radius 6px
</style>