import axios from 'axios';
import iView from 'view-design'
import sf from 'string-format';
import router from '../../router';
import {baseUrl,uploadUrl,systemCFG,getToken,getUserId} from './request';
import {clearLocalStorage} from "./localStorage";

/*不需要验证*/
const axiosHttp = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? baseUrl.dev : baseUrl.pro,
  timeout: 30000
  // withCredentials: true
});
axiosHttp.interceptors.request.use(function (config) {
  iView.LoadingBar.start()
  if(config.method === 'post'){
    // 判断是否还有params
    if (typeof(config.data) !== "undefined" && config.data.hasOwnProperty('params')) {
      let params = config.data.params
      // params对象长度
      let size = Object.keys(params).length
      // url拼接参数
      let urlParams = ""
      // 拼接params对象长度
      let count = 1
      for (let i in params) {
        // 判断长度
        if (count === 1){
          urlParams = urlParams+"?"+i+"="+params[i]
        }else {
          urlParams = urlParams+"&"+i+"="+params[i]
        }
        count++
      }
      config.url =  config.url+urlParams
    }
  }
  if (getToken() && getUserId()) {
    config.headers = {
      'headerUserId': getUserId(),
      'headerUserToken': getToken()
    }
  }
  return config;
}, function (error) {
  iView.LoadingBar.finish();
  return Promise.reject(error);
})
axiosHttp.interceptors.response.use(res => {
  iView.LoadingBar.finish();
  //-6表明身份异常或未登录
  if (res.data.status === systemCFG.userFailureCode) {
    clearLocalStorage()
    router.push({
      name: 'login'
    });
  }
  return res.data;
}, error => {
  iView.LoadingBar.finish();
  this.$Message.error("请求服务器失败，请检查服务是否正常！")
  return error
})

/*不需要验证 formdata*/
const axiosHttpFormData = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? baseUrl.dev : baseUrl.pro,
  timeout: 30000
  // withCredentials: true
});
axiosHttpFormData.interceptors.request.use(function (config) {
  iView.LoadingBar.start()
  config.headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  config.transformRequest = [
    function (data) {
      let ret = ''
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      ret = ret.substring(0, ret.lastIndexOf('&'));
      return ret
    }
  ]
  return config;
}, function (error) {
  iView.LoadingBar.finish();
  return Promise.reject(error);
})
axiosHttpFormData.interceptors.response.use(res => {
  iView.LoadingBar.finish();
  //-6表明身份异常或未登录
  if (res.data.status === systemCFG.userFailureCode) {
    clearLocalStorage()
    router.push({
      name: 'login'
    });
  }
  return res.data;
}, error => {
  iView.LoadingBar.finish();
  this.$Message.error("请求服务器失败，请检查服务是否正常！")
  return error
})

/*需要验证*/
const axiosHttpVerification = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? baseUrl.dev : baseUrl.pro,
  timeout: 30000
  // withCredentials: true
});
axiosHttpVerification.interceptors.request.use(function (config) {
  iView.LoadingBar.start()
  if(config.method === 'post'){
    // 判断是否还有params
    if (typeof(config.data) !== "undefined" && config.data.hasOwnProperty('params')) {
      let params = config.data.params
      // params对象长度
      let size = Object.keys(params).length
      // url拼接参数
      let urlParams = ""
      // 拼接params对象长度
      let count = 1
      for (let i in params) {
        // 判断长度
        if (count === 1){
          urlParams = urlParams+"?"+i+"="+params[i]
        }else {
          urlParams = urlParams+"&"+i+"="+params[i]
        }
        count++
      }
      config.url =  config.url+urlParams
    }
  }

  if (getToken() && getUserId()) {
    config.headers = {
      'headerUserId': getUserId(),
      'headerUserToken': getToken()
    }
  }
  return config;
}, function (error) {
  iView.LoadingBar.finish();
  return Promise.reject(error);
})
axiosHttpVerification.interceptors.response.use(res => {
  iView.LoadingBar.finish();
  //-6表明身份异常或未登录
  if (res.data.status === systemCFG.userFailureCode) {
    clearLocalStorage()
    router.push({
      name: 'login'
    });
  }
  return res.data;
}, error => {
  iView.LoadingBar.finish();
  this.$Message.error("请求服务器失败，请检查服务是否正常！")
  return error
})

/*上传 不需要验证*/
const axiosUpload = axios.create({
  baseURL: uploadUrl.pro,
  timeout: 30000
  // withCredentials: true
});
axiosUpload.interceptors.request.use(function (config) {
  iView.LoadingBar.start()
  if(config.method === 'post'){
    // 判断是否还有params
    if (typeof(config.data) !== "undefined" && config.data.hasOwnProperty('params')) {
      let params = config.data.params
      // params对象长度
      let size = Object.keys(params).length
      // url拼接参数
      let urlParams = ""
      // 拼接params对象长度
      let count = 1
      for (let i in params) {
        // 判断长度
        if (count === 1){
          urlParams = urlParams+"?"+i+"="+params[i]
        }else {
          urlParams = urlParams+"&"+i+"="+params[i]
        }
        count++
      }
      config.url =  config.url+urlParams
    }
  }
  if (getToken() && getUserId()) {
    config.headers = {
      'headerUserId': getUserId(),
      'headerUserToken': getToken()
    }
  }
  return config;
}, function (error) {
  iView.LoadingBar.finish();
  return Promise.reject(error);
})
axiosUpload.interceptors.response.use(res => {
  iView.LoadingBar.finish();
  //-6表明身份异常或未登录
  if (res.data.status === systemCFG.userFailureCode) {
    clearLocalStorage()
    router.push({
      name: 'login'
    });
  }
  return res.data;
}, error => {
  iView.LoadingBar.finish();
  this.$Message.error("请求服务器失败，请检查服务是否正常！")
  return error
})

export let baseRequest = {
  getV: (url, params, pathVariable = null) => axiosHttpVerification.get(sf(url, pathVariable), params),
  get: (url, params, pathVariable = null) => axiosHttp.get(sf(url, pathVariable), params),
  postV: (url, params, pathVariable = null) => axiosHttpVerification.post(sf(url, pathVariable), params),
  post: (url, params, pathVariable = null) => axiosHttp.post(sf(url, pathVariable), params),
  postFromData: (url, params, pathVariable = null) => axiosHttpFormData.post(sf(url, pathVariable), params),
  upload: (url, params, pathVariable = null) => axiosUpload.post(sf(url, pathVariable), params)
}

