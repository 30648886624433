<template>
  <div>
    <transition name="popupBottomMove">
      <div class="popup-bottom" ref="popup">
        <!--页面顶部-->
        <div class="popup-bottom-title">
          <p @click="back" class="popup-bottom-title-button">
            <Button shape="circle" type="primary" size="large" :icon="'ios-arrow-down'">关闭</Button>
          </p>
          <div class="popup-bottom-title-steps">
            <div style="width: 70%">
              <Steps :current="stepIndex">
                <Step :title="item.title" :content="item.content" v-for="(item,index) in steps" :key="item.id"></Step>
              </Steps>
            </div>
          </div>
          <div class="popup-bottom-time">{{backTime}}s</div>
        </div>
        <!--页面内容-->
        <div class="popup-bottom-body" ref="popupBottomWrapper">
          <div>
            <Carousel v-model="stepIndex" dots="none" arrow="never">
              <!--刷身份证-->
              <CarouselItem>
                <loadingPage v-show="loadingInit"></loadingPage>
                <idCardSearchOrder v-if="stepIndex === 0"  @sure="sureIdCard" @again="backTime = 300" @wzOpen="wzOpen"
                                   @wzHide="wzHide" :userId="userId"></idCardSearchOrder>
              </CarouselItem>
              <!--选择房间-->
              <CarouselItem>
                <div v-if="stepIndex === 1" class="order-list-wrapper">
                  <Card v-for="item in orderArr" :key="item.id" style="margin: 15px">
                    <div class="order-list">
                      <div>
                        <h4>{{item.roomTypeName}} {{item.roomInfoName}}</h4>
                      </div>
                      <div>
                        <Button type="success" @click="continueRoom(item)" style="margin-right: 10px">续房</Button>
                      </div>
                    </div>
                  </Card>
                </div>
              </CarouselItem>
              <!--续房-->
              <CarouselItem>
                <continueModel v-if="stepIndex === 2" :orderObj="orderObj" @payOk="goGuide"></continueModel>
              </CarouselItem>
              <!--操作流程指导-->
              <CarouselItem>
                <guide v-if="stepIndex === 3" :roomNum="roomName"></guide>
              </CarouselItem>
            </Carousel>
          </div>
        </div>
      </div>
    </transition>
    <!--微信支付-->
    <Modal v-model="flagObj.wx" title="微信支付" :mask-closable="false" @on-cancel="closeWxPay">
      <canvas ref="qrccodeCanvas" style="display: none"></canvas>
      <div class="pay-img">
        <div>
          <div class="pay-img-icon-wrapper">
            <img class="pay-img-icon" src="../../assets/wxpay.svg">
          </div>
          <img class="pay-img-qr" :src="wxPayImg" style="height: 300px">
          <p>打开微信扫一扫付款</p>
        </div>
      </div>
      <div slot="footer">
      </div>
    </Modal>
    <!--支付宝支付-->
    <Modal v-model="flagObj.al" title="支付宝支付" :mask-closable="false" @on-cancel="closeAliPay">
      <canvas ref="qrccodeCanvas" style="display: none"></canvas>
      <div class="pay-img">
        <div>
          <canvas ref="qrccodeCanvas" style="display: none"></canvas>
          <div class="pay-img-icon-wrapper">
            <img class="pay-img-icon" src="../../assets/alipay.svg">
          </div>
          <img class="pay-img-qr" :src="aliPayImg" style="height: 300px">
          <p>打开支付宝扫一扫付款</p>
        </div>
      </div>
      <div slot="footer">
      </div>
    </Modal>
    <!--未支付订单-->
    <Modal v-model="flagObj.waitPay" width="540" title="未完成订单" :closable="false" :mask-closable="false">
      <List v-if="waitPayOrder" border size="large">
        <ListItem>
          <div style="display: flex;align-items: center;justify-content: space-between;width: 100%">
            <div>订单信息</div>
            <div v-if="waitPayOrderType === 1">{{waitPayOrder.masterInfo}}</div>
            <div v-if="waitPayOrderType === 2">{{waitPayOrder.orderInfo}}</div>
          </div>
        </ListItem>
        <ListItem>
          <div style="display: flex;align-items: center;justify-content: space-between;width: 100%">
            <div v-if="waitPayOrderType === 1">入住时间</div>
            <div v-if="waitPayOrderType === 2">下单时间</div>
            <div v-if="waitPayOrderType === 1">{{waitPayOrder.orderInTime}}</div>
            <div v-if="waitPayOrderType === 2">{{waitPayOrder.createdTime}}</div>
          </div>
        </ListItem>
        <ListItem>
          <div style="display: flex;align-items: center;justify-content: space-between;width: 100%">
            <div>离店时间</div>
            <div v-if="waitPayOrderType === 1">{{waitPayOrder.orderOutTime}}</div>
            <div v-if="waitPayOrderType === 2">{{waitPayOrder.endTime}}</div>
          </div>
        </ListItem>
        <ListItem>
          <div style="display: flex;align-items: center;justify-content: space-between;width: 100%">
            <div>订单金额</div>
            <div v-if="waitPayOrderType === 1" style="color: #ed4014;font-size: 18px;font-weight: 600">￥{{(waitPayOrder.allAmount + waitPayOrder.allDeposit) / 100}}</div>
            <div v-if="waitPayOrderType === 2" style="color: #ed4014;font-size: 18px;font-weight: 600">￥{{waitPayOrder.allMoney / 100}}</div>
          </div>
        </ListItem>
      </List>
      <div slot="footer" style="display: flex;align-items: center;justify-content: space-around">
        <Button size="large" :loading="buttonLoading" @click="cancelWaitPayOrder">取消支付</Button>
        <Button type="success" size="large" :loading="buttonLoading" @click="waitPayOrderForWx">微信支付</Button>
        <Button type="info" size="large" :loading="buttonLoading" @click="waitPayOrderForAli">支付宝支付</Button>
      </div>
    </Modal>
    <!--注意查看密码-->
    <Modal v-model="flagObj.selectPassword" width="540" title="注意!" :closable="false" :mask-closable="false">
      <div style="font-size: 16px;font-weight: 600;color: #ed4014">
        续房成功，请微信扫码查看新密码!
      </div>
    </Modal>
  </div>
</template>

<script>
  import loadingPage from  "../loadingPage/loadingPage"
  import continueModel from "../continueModel/continueModel"
  import guide from "../guide/guide"
  import {getLocalStorage} from "../../common/js/localStorage"
  import idCardSearchOrder from "../idCardSearchOrder/idCardSearchOrder"
  import {baseUrl} from "../../common/js/request";

  export default {
    name: "XF",
    components: {
      loadingPage,
      continueModel,
      guide,
      idCardSearchOrder
    },
    data() {
      return {
        hotelId: null,
        steps: [
          {
            id: 0,
            title: '待进行',
            content: '请刷身份证'
          },
          {
            id: 1,
            title: '待进行',
            content: '选择房间'
          },
          {
            id: 2,
            title: '待进行',
            content: '续房'
          },
          {
            id: 3,
            title: '待进行',
            content: '入住指引'
          }
        ],
        stepIndex: 0,
        orderObj: {},
        backTime: 300,
        intervalTime: null,
        orderArr: [],
        loadingInit: true,
        roomName: 0,
        userId: 0,
        userOpenId: 0,
        phone: '',
        waitPayOrder: null,
        waitPayOrderType: 0,
        flagObj: {
          waitPay: false,
          wx: false,
          al: false,
          selectPassword: false
        },
        aliPayImg: null,
        wxPayImg: '',
        buttonLoading: false,
        wsHeart: null,
        socket: null
      }
    },
    methods: {
      /*返回首页*/
      back() {
        this.stepIndex = 0
        this.steps[this.stepIndex].title = "进行中"
        this.steps[this.stepIndex + 1].title = "待进行"
        clearInterval(this.intervalTime)
        this.$emit('back')
      },
      /*点击忘证入住*/
      wzOpen() {
        this.backTime = 300
      },
      /*关闭忘证入住*/
      wzHide() {
        this.backTime = 300
      },
      /*确定身份证核验*/
      sureIdCard(val) {
        this.idCardArr = val
        this.backTime = 300
        this.selectOrder()
      },
      //查询订单
      selectOrder() {
        let obj = {
          hotelId: this.hotelId,
          orderStatusList: "2,3,4",
          likeValue: this.idCardArr[0].cardNo
        }
        let self = this
        this.$http.get('/order/query/continue/order',{
          params: obj
        }).then(res => {
          res.result.forEach((item) => {
            if(item.id) {
              self.orderArr.push(item)
            }
          })
          self.orderArr.forEach((item) => {
            item.inUserDto = JSON.parse(item.inUserDto)
          })
          if(self.orderArr.length === 0) {
            self.$Modal.error({
              title: '没有找到订单',
              content: '没有找到订单,房间已由系统自动退房,押金将在房间清洁后原路退回',
              onOk: () => {
                self.back()
              }
            });
          }
          self.stepIndex = 1
          self.steps[this.stepIndex].title = "进行中"
          self.steps[this.stepIndex - 1].title = "已完成"
        })
      },
      /*点击续房*/
      continueRoom(obj) {
        this.roomName = obj.roomInfoName
        this.orderObj = obj
        this.userId = obj.userId
        this.stepIndex = 2
        this.steps[this.stepIndex].title = "进行中"
        this.backTime = 300
        // this.selectWaitPayOrder()
      },
      /*查询未支付订单*/
      selectWaitPayOrder() {
        this.stepIndex = 2
        this.steps[this.stepIndex].title = "进行中"
        this.backTime = 300
        return
        let self = this
        this.$http.get('/order/query/user/pay?userId='+this.userId).then(res => {
          if(res.result.length > 0) {
            self.flagObj.waitPay = true
            self.waitPayOrderType = res.result[0].newStatus
            if(res.result[0].newStatus === 1) {
              self.$http.get('/order/master/description/'+res.result[0].orderId).then(ress => {
                self.waitPayOrder = ress.result
              })
            }
            if(res.result[0].newStatus === 2) {
              self.$http.get('/order/get/continue/des?orderId='+res.result[0].orderId).then(ress => {
                self.waitPayOrder = ress.result
                self.waitPayOrder.orderInfo = res.result[0].orderInfo
              })
            }
          } else {
            self.stepIndex = 2
            self.steps[self.stepIndex].title = "进行中"
            self.backTime = 300
          }
        })
      },
      /*取消待支付订单*/
      cancelWaitPayOrder() {
        let self = this
        self.buttonLoading = true
        if(this.waitPayOrderType === 1) {
          this.$http.get('/order/cancel/'+this.waitPayOrder.masterId).then(res => {
            self.flagObj.waitPay = false
            self.buttonLoading = false
            self.wxPayImg = ''
            self.aliPayImg = ''
            self.stepIndex = 2
            self.steps[self.stepIndex].title = "进行中"
            self.backTime = 300
          })
        }
        if(this.waitPayOrderType === 2) {
          this.$http.post('/order/continue/room/cancel?continueId='+this.waitPayOrder.id).then(res => {
            self.flagObj.waitPay = false
            self.wxPayImg = ''
            self.aliPayImg = ''
            self.buttonLoading = false
            self.stepIndex = 2
            self.steps[self.stepIndex].title = "进行中"
            self.backTime = 300
          })
        }
      },
      /*待支付 微信支付*/
      waitPayOrderForWx() {
        let id = 0
        let obj = {}
        if(this.waitPayOrderType === 1) {
          id = this.waitPayOrder.masterId
          obj = {
            orderMasterId: id,
            type: '3',
            hotelId: this.hotelId
          }
        }
        if(this.waitPayOrderType === 2) {
          id = this.waitPayOrder.id
          obj = {
            orderMasterId: id,
            type: '2',
            id: this.waitPayOrder.masterId,
            hotelId: this.hotelId
          }
        }
        let self = this
        self.buttonLoading = true
        this.$http.postFromData(baseUrl.dev + '/wx/mini/create/unlimit/wx/qrcode', {
          path: 'pages/codePay/codePay',
          scene: JSON.stringify(obj)
        }).then(re => {
          self.flagObj.wx = true
          self.buttonLoading = false
          self.flagObj.waitPay = false
          self.wxPayImg = re
          if(!self.socket) {
            self.createWS()
          }
        })
      },
      /*待支付 支付宝支付*/
      waitPayOrderForAli() {
        let id = 0
        if(this.waitPayOrderType === 1) {
          id = this.waitPayOrder.masterId
        }
        if(this.waitPayOrderType === 2) {
          id = this.waitPayOrder.id
        }
        let self = this
        self.buttonLoading = true
        this.$http.post('/pay/alipay/create/order/launch?orderMasterId='+id).then(re => {
          self.canvasDiv = self.$refs.qrccodeCanvas
          QRCode.toCanvas(self.canvasDiv, re.result, {
            width: 300
          }, (error) => {
            self.flagObj.al = true
            self.buttonLoading = false
            self.flagObj.waitPay = false
            if (error) {
              self.$Message.error('二维码生成失败')
            } else {
              if(!self.socket) {
                self.createWS()
              }
              let myCanvas = self.$refs.qrccodeCanvas
              self.aliPayImg = myCanvas.toDataURL('image/png')
            }
          })
        })
      },
      /*建立websocket连接*/
      createWS() {
        if (typeof (WebSocket) === "undefined") {
          alert("您的浏览器不支持socket")
        } else {
          // 实例化socket
          this.socket = new WebSocket(baseUrl.wss)
          // 监听socket连接
          this.socket.onopen = this.WSOpen
          // 监听socket错误信息
          this.socket.onerror = this.WSError
          // 监听socket消息
          this.socket.onmessage = this.WSMessage
          this.socket.onclose = () => {
            console.log('websocket关闭了')
          }
        }
      },
      //ws注册
      registerWS() {
        let obj = {
          id: this.hotelId,
          sendDevice: "PC_CLIENT",
          acceptDevice: "MA_CLIENT",
          cmd: "ws_register",
          data: ""
        }
        this.socket.send(JSON.stringify(obj))
        this.sendHeartWS()
      },
      //ws心跳
      sendHeartWS() {
        this.wsHeart = setInterval(() => {
          let obj = {
            id: this.hotelId,
            sendDevice: "PC_CLIENT",
            acceptDevice: "MA_CLIENT",
            cmd: "ws_heart",
            data: ""
          }
          this.socket.send(JSON.stringify(obj))
        }, 30000)
      },
      /* 监听socket连接*/
      WSOpen() {
        console.log('连接成功')
        this.registerWS()
      },
      //关闭链接
      closeWS() {
        let obj = {
          id: this.hotelId,
          sendDevice: "PC_CLIENT",
          acceptDevice: "MA_CLIENT",
          cmd: "pc_close_ma",
          data: ""
        }
        if(this.socket) {
          this.socket.close()
          this.socket.send(JSON.stringify(obj))
        }
        clearInterval(this.wsHeart)
        console.log('关闭ws')
      },
      // 监听socket消息
      WSMessage(res) {
        console.log('监听消息')
        const message = JSON.parse(res.data)
        if(message.cmd === 'order_pay_success') {
          this.flagObj.al = false
          this.flagObj.wx = false
          this.goGuide()
          this.closeWS()
        }
      },
      /*关闭微信支付*/
      closeWxPay() {
        if(this.waitPayOrderType !== 0) {
          this.flagObj.waitPay = true
        } else {
          this.backTime = 300
          this.flagObj.wx = false
        }
      },
      /*关闭支付宝支付*/
      closeAliPay() {
        if(this.waitPayOrderType !== 0) {
          this.flagObj.waitPay = true
        } else {
          this.backTime = 300
          this.flagObj.al = false
        }
      },
      /*续房完成*/
      goGuide() {
        this.flagObj.selectPassword = true
        this.stepIndex = 3
        this.steps[this.stepIndex].title = "进行中"
        this.backTime = 60
      },
      /*页面倒计时*/
      pageOutTime() {
        this.intervalTime = setInterval(() => {
          this.backTime--
          if(this.backTime <= 0) {
            clearInterval(this.intervalTime)
            this.stepIndex = 0
            this.steps[0].title = "进行中"
            this.steps[1].title = "待进行"
            this.steps[2].title = "待进行"
            this.steps[3].title = "待进行"
            this.$emit('back')
          }
        }, 1000)
      }
    },
    created() {
      let self = this
      setTimeout(() => {
        self.hotelId = getLocalStorage("zs-offline-hotelId")
        self.backTime = 300
        self.pageOutTime()
      }, 200)
      setTimeout(() => {
        self.loadingInit = false
      }, 1000)
    },
    filters: {
      goodOverFlow(val) {
        let v = parseFloat(val)
        return v.toFixed(2)
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalTime)
      this.closeWS()
      this.loadingInit = true
    }
  }
</script>

<style scoped lang="stylus">
  .popup-bottom
    bottom 0
    left 0
    width 100%
    height 100%
    position fixed
    font-size 15px
    background-color #fff
    z-index 999
    overflow hidden
    .popup-bottom-title
      width 100%
      padding 18px 22px
      display flex
      background-color #ffffff
      border-bottom 1px solid #dcdee2
      .popup-bottom-title-steps
        width 100%
        display flex
        align-items center
        justify-content center
    .popup-bottom-body
      top 81px
      left 0
      width 100%
      height 100%
      position absolute
      overflow auto
  .popup-bc
    top 0
    left 0
    width 100%
    height 100%
    position fixed
    z-index 998
    background-color rgba(0, 0, 0, .6)
  .outTime
    margin 32px 0
    text-align center
    font-size 22px
  .time-button
    margin-top 24px
    padding-bottom 81px
    width 100%
    display flex
    align-items center
    justify-content center
  .type-item-wrapper
    padding 24px 60px
    display flex
    align-items center
    justify-content space-around
    flex-wrap wrap
    .type-item
      display flex
      align-items center
      justify-content flex-start
      .type-item-left
        width 168px
        height 168px
        margin-right 18px
        position relative
        overflow hidden
        border-radius 6px
        > img
          height 168px
        .type-item-surplus
          bottom 0
          left 0
          width 100%
          position absolute
          padding 8px
          background-color rgba(0,0,0, .5)
          color #ffffff
          text-align center
          font-size 14px
        .type-item-surplus-no
          bottom 0
          left 0
          width 100%
          position absolute
          padding 8px
          color #808695
          text-align center
          font-size 14px
          background-color rgba(197,200,206, .9)
    .type-item-right
      width calc(100% - 186px)
      height 100%
      h4
        margin-bottom 12px
        font-size 18px
      .price-day
        margin-top 12px
        display flex
        justify-content flex-end
        text-align right
        font-size 22px
        h5
          color #ed4014
          font-size 22px
  .room-div
    margin 24px
    padding 12px 24px
    background-color #2d8cf0
    border-radius 4px
    color #ffffff
  .order-item
    width 100%
    display flex
    align-items center
    justify-content space-between
    color #808695
    > span
      color #515a6e
  .time-wrapper
    width 100%
    display flex
    align-items flex-start
    justify-content center
    .time-left
      width 20%
      height 100vh
      border-right: 1px solid #e8eaec
      > h4
        margin-top 32px
        text-align center
        font-size 22px
      .time-action
        width 100%
        height calc(100% - 200px)
        display flex
        align-items center
        .time-action-item
          width 150px
          height 150px
          margin 0 auto
          display flex
          align-items center
          justify-content center
          border-radius 50%
          background-color #808695
          color #ffffff
          font-size 22px
        .time-action-item-yes
          background-color #2d8cf0 !important
  .zh-type-wrapper
    display flex
    align-items center
    justify-content center
    .zh-type
      height calc(100vh - 81px)
      width 50%
      display flex
      align-items center
      justify-content center
      text-align center
      img
        width 100px
      h4
        margin 20px 0
        font-size 18px
        font-weight bold
      p
        font-size 14px
        font-weight 400
      .zh-type-item
        min-width 350px
  .order-list-wrapper
    margin-top 5%
    padding 0 40px
    display flex
    align-items center
    justify-content space-between
    flex-wrap wrap
    .order-list
      width 400px
      padding 15px
      display flex
      align-items center
      justify-content space-between
      h4
        font-size 18px
  .popup-bottom-time
    font-size 22px
    line-height 44px
    font-weight 600
  .idCard-wrapper
    width 100%
    height 687px
    padding 24px
    overflow hidden
    > h4
      margin-top 24px
      font-size 28px
      text-align center
  .idCard-img-wrapper
    margin-top 24px
    display flex
    align-items center
    justify-content center
    .idCard-img
      width auto
      height 450px
  .tf-label
    margin-top 6px
    font-size 14px
    color #ff9900
  .idCard-submit
    margin-top 40px
    display flex
    align-items center
    justify-content center
  .popupBottomMove-enter-active, .popupBottomMove-leave-active
    transition all .4s
  .popupBottomMove-enter, .popupBottomMove-leave-to
    transform translateY(100%)
  .popupBottomShow-enter-active, .popupBottomShow-leave-active
    transition all .4s
    opacity 1
  .popupBottomShow-enter, .popupBottomShow-leave-to
    opacity 0
  .moveTop-enter-active, .moveTop-leave-active
    transition all .4s
    opacity 1
  .moveTop-enter, .moveTop-leave-to
    transform translateY(100%)
    opacity 0
</style>
