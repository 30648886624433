<template>
  <div class="phone-wrapper">
    <audio autoplay="autoplay" v-show="false">
      <source src="../../assets/audio/name.mp3" type="audio/mpeg">
    </audio>
    <h4 v-show="!topFlag">请输入您在其他平台的下单姓名</h4>
    <div class="phone-img">
      <img src="../../assets/name.svg">
    </div>
    <div class="phone-div">
      <div class="phone-div-div">
        <Input size="large" v-model="name" @on-change="changeName()" @on-focus="topFlag = true">
          <span slot="prepend">下单姓名</span>
        </Input>
      </div>
    </div>
    <transition name="moveTop">
      <div v-if="flagName" class="time-button">
        <div style="width: 40%">
          <Button type="primary" size="large" long @click="sureName">提交</Button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: "name",
    data() {
      return {
        name: null,
        flagName: false,
        topFlag: false
      }
    },
    methods: {
      /*获取手机号码长度，然后显示确定按钮*/
      changeName() {
        if(this.name) {
          this.flagName = true
        } else {
          this.flagName = false
        }
      },
      /*确定提交手机号码*/
      sureName() {
        this.$emit('sureName', this.name)
      }
    },
    created() {
      this.name = null
    }
  }
</script>

<style scoped lang="stylus">
  .phone-wrapper
    padding 24px
    > h4
      margin 24px 0 48px 0
      font-size 22px
      text-align center
    .phone-img
      margin-bottom 48px
      display flex
      align-items center
      justify-content center
      > img
        width 128px
    .phone-div
      display flex
      align-items center
      justify-content center
      .phone-div-div
        width 50%
  .time-button
    margin-top 60px
    padding-bottom 81px
    width 100%
    display flex
    align-items center
    justify-content center
  .phone-div-top
    margin-top 70px
    width 100%
    display flex
    align-items center
    justify-content center
    .phone-div-div-top
      width 50%
  .moveTop-enter-active, .moveTop-leave-active
    transition all .4s
    opacity 1
  .moveTop-enter, .moveTop-leave-to
    transform translateY(100%)
    opacity 0
</style>