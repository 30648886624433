<template>
  <div>
    <!--语音播报-->
    <audio autoplay="autoplay" v-show="false">
      <source src="../../assets/audio/guide.mp3" type="audio/mpeg">
    </audio>
    <h1 class="guide-title">{{hotelOpenDoorType === 1 ? "查看开门密码" : "开门指引"}}</h1>
    <div v-if="hotelOpenDoorType === 2 || hotelOpenDoorType === 3" class="guide-wrapper">
      <div>
        <img src="../../assets/hotelCard.jpeg">
        <h2 style="margin-top: 10px">拿到酒店房卡,刷卡入住</h2>
      </div>
    </div>
    <div v-if="hotelOpenDoorType === 1" class="guide-wrapper">
      <div class="guide-item">
        <div style="height: 400px;margin-bottom: 25px;display: flex;align-items: center">
          <img class="guide-item-img" src="../../assets/code-mp.jpg" style="height: 200px;width: auto">
        </div>
        <h2 class="guide-item-font">请微信扫码</h2>
      </div>
      <div class="guide-item">
        <img class="guide-item-img" src="../../assets/end1.jpg" style="height: 400px;width: auto">
        <h2 class="guide-item-font">点击关注</h2>
      </div>
      <div class="guide-item">
        <img class="guide-item-img" src="../../assets/end2.png" style="height: 400px;width: auto">
        <h2 class="guide-item-font">点击开门</h2>
      </div>
      <div class="guide-item">
        <h4 class="guide-item-room">{{roomNum}}</h4>
        <img class="guide-item-img" src="../../assets/guide1.png" style="height: 400px">
        <h2 class="guide-item-font">入住房间</h2>
      </div>
    </div>
  </div>
</template>

<script>
  import {getLocalStorage} from "../../common/js/localStorage";

  export default {
    name: "guide",
    props: {
      roomNum: String
    },
    data() {
      return {
        hotelOpenDoorType: 2
      }
    },
    created() {
      this.hotelOpenDoorType = getLocalStorage('zs-offline-hotelDetailsObj').hotelOpenDoorType
    }
  }
</script>

<style scoped>
  .guide-title {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: #17233d;
  }

  .guide-wrapper {
    padding: 30px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-size: 14px;
    color: #17233d;
  }

  .guide-item{
    position: relative;
  }

  .guide-item-img {
    position: relative;
    width: 200px;
    margin-bottom: 20px;
  }

  .guide-item-right{
    position: relative;
    width: 150px;
    margin-bottom: 20px;
  }

  .guide-item-room {
    top: 30px;
    left: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 999;
    color: #ffffff;
    letter-spacing: 2px;
    font-size: 22px;
  }
</style>