<template>
  <div class="idCard-wrapper">
    <!--语音播报 放置身份证-->
    <audio autoplay="autoplay" v-show="false">
      <source src="../../assets/audio/idcard.mp3" type="audio/mpeg">
    </audio>
    <!--语音播报 对准摄像头-->
    <template v-if="cardIndex === 1">
      <audio autoplay="autoplay" v-show="false">
        <source src="../../assets/audio/camera.mp3" type="audio/mpeg">
      </audio>
    </template>
    <!--语音播报 继续添加入住人-->
    <template v-if="cardIndex === 2">
      <div style="top: -100%;position: fixed;">
        <audio autoplay="autoplay" controls>
          <source src="../../assets/audio/agenIdCard.mp3" type="audio/ogg">
          <source src="../../assets/audio/agenIdCard.mp3" type="audio/mpeg">
        </audio>
      </div>
    </template>
    <h4>{{cardTitle}}</h4>
    <div v-show="cardIndex === 0" class="idCard-img-wrapper">
      <img class="idCard-img" src="../../assets/sfz.png">
    </div>
    <!--身份证消磁-->
    <div v-if="showWZ && cardIndex === 0" class="idCard-submit">
      <Button type="warning" size="large" @click="goWZ">输入身份证号码</Button>
    </div>
    <!--忘证入住-->
    <Modal v-model="wzFlag" title="请输入身份证号码" width="500" @on-cancel="closeWZ" :mask-closable="false">
      <div>
        <Form :label-width="100">
          <!--<FormItem label="姓名">
            <Input prefix="ios-contact" v-model="wzObj.Name" maxlength="18" placeholder="请输入姓名"/>
          </FormItem>-->
          <FormItem label="身份证号码">
            <Input prefix="ios-card" v-model="wzObj.CredentialsNo" maxlength="18" placeholder="请输入身份证号码"
                   @on-change="changeIdCard"/>
          </FormItem>
          <!--<FormItem label="入住人" v-if="idCardArr.length !== 0">
            <Tag type="dot" color="success" closable @on-close="delIdCardArr(index)" v-for="(item,index) in idCardArr"
                 :key="item.name" size="large">{{item.name}} - {{item.cardNo}}
            </Tag>
          </FormItem>-->
        </Form>
      </div>
      <div slot="footer">
        <Button type="primary" @click="submitWZ" :loading="wzLoading">提交</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {getLocalStorage} from "../../common/js/localStorage"
  import {unique} from "../../common/js/utils"
  import {convertRaceData} from "../../common/js/nationConversion"

  export default {
    name: "idCardSearchOrder",
    props: {
      userId: 0
    },
    data() {
      return {
        showWZ: false,
        interval: null,
        cardIndex: 0, //根据步骤进行相应的显示
        cardButton: false, //显示下一步按钮
        cardButtonFail: false, //识别错误重新识别
        idCardArr: [],
        idCardObj: null,
        cardTitle: '请将身份证放至在识别区',
        orderObj: {},
        outTime: null,
        outTimeRestart: null,
        orderFlag: false,
        outTimeNo: null,
        wzFlag: false,
        wzObj: {
          orderName: '',
          Name: '',
          CredentialsNo: ''
        },
        wzLoading: false,
        ws: null,
        idCardType: 1 //1精轮 2鱼住
      }
    },
    methods: {
      /*打开输入身份证号码和姓名*/
      goWZ() {
        this.wzFlag = true
        this.$emit('wzOpen')
        clearTimeout(this.outTimeNo)
        let self = this
        setTimeout(() => {
          /*精轮*/
          if(self.idCardType === 1) {
            clearInterval(self.interval)
          }
          /*鱼住*/
          if(self.idCardType === 2) {
            self.ws.close()
          }
        }, 1000)
      },
      /*关闭输入身份证和姓名*/
      closeWZ() {
        this.$emit('wzHide')
        /*精轮*/
        if(this.idCardType === 1) {
          /*if (this.orderType === 1) {
            /!*获取平台订房身份证数据*!/
            this.interval = setInterval(() => {
              //this.getIdCardDataPQ()
            }, 3000)
          } else {
            /!*获取线下 渠道订房身份证数据*!/
            this.interval = setInterval(() => {
              //this.getIdCardDataXX()
            }, 3000)
          }*/
        }
        /*鱼住*/
        if(this.idCardType === 2) {
          this.getWebSocket()
        }
      },
      /*添加入住人*/
      changeIdCard() {
        if (this.wzObj.CredentialsNo.length === 18) {
          this.wzLoading = true
          /*if (!this.wzObj.Name) {
            this.$Notice.error({
              title: '输入错误',
              desc: '请输入姓名!',
              duration: 5
            });
            this.wzLoading = false
            return
          }*/
          if (!this.wzObj.CredentialsNo || this.wzObj.CredentialsNo.length !== 18) {
            this.$Notice.error({
              title: '输入错误',
              desc: '请输入身份证号码!',
              duration: 5
            });
            this.wzLoading = false
            return
          }
          let self = this
          let idNo = {
            idCard: this.wzObj.CredentialsNo.toUpperCase(),
            name: this.wzObj.Name
          }
          let obj = {
            name: '',
            phone: '',
            cardNo: this.wzObj.CredentialsNo,
            isAll: 1,
            idCardDto: {
              name: '', //姓名
              birthDay: '',//出生年月
              idNo: this.wzObj.CredentialsNo,//身份证号码
              cover: '',//身份证头像
              address: '',//详细地址
              validDate: '',//有效年份
              issueAgency: '',//颁发机关
              nation: '',//名族
              sex: '',//性别
              type: '11',//证件类型
              city: ''//城市代码
            },
            userId: self.userId
          }
          self.idCardArr.push(obj)
          self.idCardArr = unique(self.idCardArr)
          self.wzObj.Name = ''
          self.wzObj.CredentialsNo = ''
          this.submitWZ()
        }
      },
      /*删除入住人*/
      delIdCardArr(index) {
        this.idCardArr.splice(index, 1);
      },
      /*提交身份证和姓名核验*/
      submitWZ() {
        this.wzLoading = false
        this.wzFlag = false
        this.$emit('sure', this.idCardArr)
      },
      /*打开摄像头 只能本地HTML文件或者https下面才能打开 否则会报错*/
      openCamera() {
        console.log('打开中')
        let constraints = {
          video: {width: 500, height: 500},
          audio: true
        };
        /*
        这里介绍新的方法:H5新媒体接口 navigator.mediaDevices.getUserMedia()
        这个方法会提示用户是否允许媒体输入,(媒体输入主要包括相机,视频采集设备,屏幕共享服务,麦克风,A/D转换器等)
        返回的是一个Promise对象。
        如果用户同意使用权限,则会将 MediaStream对象作为resolve()的参数传给then()
        如果用户拒绝使用权限,或者请求的媒体资源不可用,则会将 PermissionDeniedError作为reject()的参数传给catch()
        */
        let promise = navigator.mediaDevices.getUserMedia(constraints);
        promise.then(function (MediaStream) {
          video.srcObject = MediaStream;
          video.play();
        }).catch(function (PermissionDeniedError) {
          console.log(PermissionDeniedError);
        })
        console.log('打开完毕')
        this.outTime = setTimeout(() => {
          this.takePhoto()
        }, 2000)
      },
      /*拍照*/
      takePhoto() {
        this.cardTitle = "正在核验,请稍后"
        this.$Spin.show()
        let canvas = document.getElementById("canvas")
        let video = document.getElementById("video")
        let ctx = canvas.getContext('2d')
        ctx.drawImage(video, 0, 0, 400, 300)
        let image = canvas.toDataURL('image/jpeg')
        let arr = image.split(',')
        let img = arr[1]
        let self = this
        setTimeout(() => {
          let obj = {
            face: img,
            img: self.idCardObj.idCardDto.cover
          }
          this.$http.post('/verify/baidu/face', obj).then((response) => {
            self.$Spin.hide()
            /*精轮*/
            if(self.idCardType === 1) {
              if (self.interval) {
                clearInterval(self.interval)
              }
            }
            /*鱼住*/
            if(self.idCardType === 2) {
              self.ws.close = () => {
                console.log('websocket关闭')
              }
            }
            if (response.status === 200) {
              if (response.result > 50) {
                self.cardIndex = 2
                self.cardTitle = "核验成功"
                self.cardButton = true
                self.cardButtonFail = false
                self.idCardArr.push(self.idCardObj)
                self.idCardArr = unique(self.idCardArr)
              } else {
                self.cardTitle = "核验失败"
                self.cardButtonFail = true
                self.cardButton = false
              }
            } else {
              self.cardTitle = "核验失败"
              self.cardButtonFail = true
              self.cardButton = false
            }
          }).catch(() => {
            self.$Spin.hide()
          })
        }, 200)
      },
      /*继续添加入住人*/
      again() {
        this.idCardArr.push(this.idCardObj)
        this.idCardObj = null
        this.cardIndex = 0
        this.cardTitle = '请将身份证放至在识别区'
        this.cardButtonFail = false
        this.cardButton = false
        /*精轮*/
        if(this.idCardType === 1) {
          /*if(this.orderType === 1) {
            /!*获取平台订房身份证数据*!/
            this.interval = setInterval(() => {
              this.getIdCardDataPQ()
            }, 3000)
          } else {
            /!*获取线下 渠道订房身份证数据*!/
            this.interval = setInterval(() => {
              this.getIdCardDataXX()
            }, 3000)
          }*/
        }
        /*鱼住*/
        if(this.idCardType === 2) {
          this.getWebSocket()
        }
        this.$emit('again')
      },
      /*提交身份证核验*/
      sure() {
        this.orderFlag = false
        this.$emit('sure', this.idCardArr)
      },
      /*重新核验*/
      restart() {
        this.cardTitle = "请对准摄像头"
        this.cardIndex = 1
        this.cardButtonFail = false
        this.cardButton = false
        this.outTimeRestart = setTimeout(() => {
          this.takePhoto()
        }, 2000)
      },
      /*获取身份证是否读取 平台 精轮*/
      getIdCardDataPQ() {
        let self = this
        let params = new URLSearchParams()
        params.append('hotel_code', self.hotelCode)
        params.append('type', self.orderType)
        this.$http.post(DN + 'idcard/idcard_data', params).then((response) => {
          response = response.data
          if (response.code === 200) {
            if (response.order != null || response.order) {
              self.orderFlag = true
              self.orderObj = response.order
              self.roomNum = response.room
              self.cardTitle = '请对准摄像头'
              self.idCardObj = response.data
              self.cardIndex = 1
              self.openCamera() //打开摄像头
            } else {
              if (self.orderFlag) {
                self.cardTitle = '请对准摄像头'
                self.idCardObj = response.data
                self.cardIndex = 1
                self.openCamera() //打开摄像头
              } else {
                self.nonePT = true
              }
            }
          }
        })
      },
      /*建立websocket连接并获取身份证信息 鱼住*/
      getWebSocket() {
        this.ws = new WebSocket("ws://127.0.0.1:30002/ws");
        // this.ws = new WebSocket("ws://192.168.1.43:30002/ws");
        let self = this
        self.ws.onmessage = function (messageEvent) {
          let reader = new FileReader();
          let newblob = messageEvent.data.slice(4); // 去掉前面四位的长度
          reader.readAsText(newblob, 'utf-8');
          reader.onload = function (evt) {
            let szjson = evt.target.result;
            let jsonobject = JSON.parse(szjson.substring(0, szjson.length - 3)); // 去掉后面的"end"字符串
            if (jsonobject.Ret === 0) {
              if (jsonobject.Cmd === 10001) {
                let szparam = JSON.parse(window.atob(jsonobject.UserParam));
                let name = self.hex2a(window.atob(szparam.CardInfo.Name)); //姓名
                let SN = szparam.CardInfo.SN; //SN
                let idcard = self.hex2a(window.atob(szparam.CardInfo.No)); //身份证
                let sex = self.hex2a(window.atob(szparam.CardInfo.Sex)); //性别
                let birthday = self.hex2a(window.atob(szparam.CardInfo.Birthday));//生日
                let nation = self.hex2a(window.atob(szparam.CardInfo.Nation)); //民族
                let address = self.hex2a(window.atob(szparam.CardInfo.Address)); //地址
                let department = self.hex2a(window.atob(szparam.CardInfo.SignedDepartment)); //签发机关
                let startTime = self.hex2a(window.atob(szparam.CardInfo.ValidityPeriodBegin)); //有效期开始
                let endTime = self.hex2a(window.atob(szparam.CardInfo.ValidityPeriodEnd));//有效期结束
                let src = szparam.BmpInfo; //照片
                /*读取到信息后关闭websocket*/
                self.ws.close()
                let idCardObj = {
                  name: name.replace(/\s*/g,""),
                  phone: '',
                  cardNo: idcard.replace(/\s*/g,""),
                  isAll: 1,
                  idCardDto: {
                    name: name.replace(/\s*/g,""), //姓名
                    birthDay: birthday.replace(/\s*/g,""),//出生年月
                    idNo: idcard.replace(/\s*/g,""),//身份证号码
                    cover: src,//身份证头像
                    address: address.replace(/\s*/g,""),//详细地址
                    validDate: '',//有效年份
                    issueAgency: department.replace(/\s*/g,""),//颁发机关
                    nation: parseInt(nation),//民族
                    sex: sex,//性别
                    type: '11',//证件类型
                    city: idcard.substring(0, 6)//城市代码
                  },
                  userId: self.userId
                }
                self.idCardObj = idCardObj
                self.idCardArr.push(idCardObj)
                self.$emit('sure', self.idCardArr)
              } else {
                self.getIdCardDataYZ()
              }
            } else {
              self.getIdCardDataYZ()
            }
          }
        }
        /*建立连接时触发 获取身份证读取*/
        self.ws.onopen = () => {
          console.log('websocket建立通讯')
          self.getIdCardDataYZ()
        }
        self.ws.onclose = () => {
          console.log('websocket关闭了')
        }
      },
      /*解码 鱼住*/
      hex2a(hex) {
        let str_list = "";
        for (let i = 0;
             (i < hex.length && hex.substr(i, 2) !== '00'); i += 2) {
          let a = hex.charCodeAt(i);
          let b = hex.charCodeAt(i + 1);
          let c = b * 256 + a;
          str_list += String.fromCharCode(c);
        }

        return str_list.toString();
      },
      /*获取身份证是否读取 鱼住*/
      getIdCardDataYZ() {
        let szJson = '{\"Cmd\":10000,\"Head\":\"YZWL\",\"IPFlag\":\"YWYyNWMxOWQ1ZTY4ZmJhOQ==\",\"UserParam\":\"eyJBcHBLZXkiOiI0OEU3MzkyQzM5RjhCRTgwMkFBMTM3RjJDQTZFMTAwQiIsIkRlY29kZVBob3RvIjp0cnVlLCJGYWNlQ29tcGFyZSI6dHJ1ZSwiUGhvdG9Gb3JtYXQiOjEsIlNlcnZlcklQIjoiaWQueXpmdXR1cmUuY24iLCJTZXJ2ZXJQb3J0Ijo4ODQ4fQo=\",\"Version\":\"V1.0.0\"}\n';
        let szEnd = 'end';
        let msg = String.fromCharCode("0x29");
        msg += String.fromCharCode("0x01");
        msg += String.fromCharCode("0x00");
        msg += String.fromCharCode("0x00");
        msg += szJson;
        msg += szEnd;
        this.ws.send(msg);
      },
      /*获取酒店设置忘证开关*/
      getWZdata() {
        let self = this
        this.$http.get('/home/hotel/des/'+this.hotelId).then(res => {
          if(parseInt(res.result.hotelIdCardVerificationStatus) === 1) {
            self.showWZ = true
          } else {
            self.showWZ = false
          }
        })
      }
    },
    created() {
      let self = this
      setTimeout(() => {
        self.hotelId = getLocalStorage("zs-offline-hotelId")
        self.idCardType = parseInt(getLocalStorage("zs-offline-hotelDetailsObj").hotelIdCardReadType)
        self.getWZdata()
        /*精轮*/
        if (self.idCardType === 1) {
          /*if (self.orderType === 1) {
            /!*获取平台订房身份证数据*!/
            self.interval = setInterval(() => {
              self.getIdCardDataPQ()
            }, 3000)
          } else {
            /!*获取线下 渠道订房身份证数据*!/
            self.interval = setInterval(() => {
              self.getIdCardDataXX()
            }, 3000)
          }*/
        }
        /*鱼住*/
        if (self.idCardType === 2) {
          self.getWebSocket()
        }
        self.outTimeNo = setTimeout(() => {
          if (self.cardIndex === 0) {
            self.$Notice.warning({
              title: '请重新将身份证放置在识别区',
              desc: '在听到[滴]的一声后,多停留3秒',
              duration: 5
            });
          }
        }, 10000)
      }, 200)
    },
    beforeDestroy() {
      console.log("页面关闭")
      this.idCardArr = []
      this.idCardObj = null
      this.$Spin.hide()
      /*精轮*/
      if (this.idCardType === 1) {
        clearInterval(this.interval)
      }
      /*鱼住*/
      if (this.idCardType === 2) {
        if(this.ws) {
          this.ws.close()
        }
      }
      clearTimeout(this.outTime)
      clearTimeout(this.outTimeRestart)
      clearTimeout(this.outTimeNo)
    }
  }
</script>

<style scoped lang="stylus">
  .idCard-wrapper
    width 100%
    height 687px
    padding 24px
    overflow hidden

    > h4
      margin-top 24px
      font-size 28px
      text-align center

    .idCard-video-wrapper
      margin-top 24px
      display flex
      align-items center
      justify-content center

      .idCard-video
        height 300px
        border-radius 12px

    .idCard-img-wrapper
      margin-top 24px
      display flex
      align-items center
      justify-content center

      .idCard-img
        width auto
        height 450px

    .idCard-submit
      margin-top 30px
      display flex
      align-items center
      justify-content center

  .moveTop-enter-active, .moveTop-leave-active
    transition all .4s
    opacity 1

  .moveTop-enter, .moveTop-leave-to
    transform translateY(100%)
    opacity 0
</style>