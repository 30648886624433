<template>
  <div>test</div>
</template>

<script>
export default {
  name: "test",
  data() {
    return {}
  },
  created() {
    this.test()
  },
  methods: {

  }
}
</script>

<style scoped>

</style>